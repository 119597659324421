import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-remberg-card',
  templateUrl: './remberg-card.component.html',
  styleUrls: ['./remberg-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RembergCardComponent {
  @Input() public hasContent = true;
}
