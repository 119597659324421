<app-multiselect-chip-list
  [title]="translations.title"
  [titleIcon]="titleIcon"
  [titleIconSvg]="titleIconSvg"
  [functions]="functions"
  [disabled]="disabled"
  [readonly]="readonly"
  [multiselectControl]="multiselectControl"
  [linkable]="linkable"
  [isMultiselectOpenedOnInit]="isMultiselectOpenedOnInit"
  [emptyListType]="emptyListType"
  [emptyItemsText]="translations.emptyItemsText"
  [isEmptyItemsTextVisible]="isEmptyItemsTextVisible"
  [isShowMoreFeatureEnabled]="isShowMoreFeatureEnabled"
  [matSelectPanelClass]="matSelectPanelClass"
  [placeDropdownNextToButton]="placeDropdownNextToButton"
>
</app-multiselect-chip-list>
