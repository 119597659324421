import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LogService } from '@remberg/global/ui';
import { TicketsPermissionEnum } from '@remberg/tickets2/common/main';
import { ToastrService } from 'ngx-toastr';
import { UserRightsService } from '../../services/user-rights.service';

@Injectable({
  providedIn: 'root',
})
export class TicketsGuard {
  constructor(
    private userRightsService: UserRightsService,
    private router: Router,
    private logger: LogService,
    private toastr: ToastrService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.userRightsService.getPermission(TicketsPermissionEnum.TICKETS_ENABLED)) {
        return resolve(true);
      }
      this.logger.info()('Tickets Guard: Redirecting from: ' + state.url);
      this.toastr.warning(
        $localize`:@@noTasksFeature:Account feature "Tickets" is not granted.`,
        $localize`:@@warning:Warning`,
      );
      this.router.navigate(['/']);
      return resolve(false);
    });
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
