export enum AssetsPermissionEnum {
  ASSETS_ENABLED = 'assets_enabled',
  ASSETS_CREATE = 'assets_create',
  ASSETS_UPDATELOCATION = 'assets_updateLocation',
  ASSETS_UPDATEATTRIBUTES = 'assets_updateAttributes',
  ASSETS_UPDATEOWNCONTACTPERSON = 'assets_updateOwnContactPerson',
  ASSETS_UPDATEEXTORGANIZATION = 'assets_updateExtOrganization',
  ASSETS_UPDATEEXTCONTACTPERSON = 'assets_updateExtContactPerson',
  ASSETS_DELETE = 'assets_delete',
  ASSETS_CREATETYPE = 'assets_createType',
  ASSETS_UPDATETYPE = 'assets_updateType',
  ASSETS_DELETETYPE = 'assets_deleteType',
  ASSETS_NOTES_CREATE_UPDATE_DELETE_OWN = 'assets_notesCreateUpdateDeleteOwn',
  ASSETS_NOTES_DELETE_ALL = 'assets_notesDeleteAll',
}
