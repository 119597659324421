export enum Tickets2SortFieldsEnum {
  COUNTER = 'counter',
  SUBJECT = 'subject',
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PRIORITY = 'priority',
  SUPPORT_EMAIL_ADDRESS = 'supportEmailAddress',
  CREATED_BY_TYPE = 'createdByType',
  STATUS_TIME = 'statusTime',
  RESOLUTION_TIME = 'resolutionTime',
}
