import { createMapper } from '@remberg/global/common/core';
import { AssetTypeBasic } from '../../asset-types';
import { ProductType } from '../definitions/product-type';

function mapProductTypeToAssetTypeBasic(productType: ProductType): AssetTypeBasic {
  return {
    _id: productType._id,
    label: productType.label,
    imageId: productType.image,
  };
}

export const mapToAssetTypeBasic = createMapper(mapProductTypeToAssetTypeBasic);
