import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { LogService } from '@remberg/global/ui';
import { TicketsPermissionEnum } from '@remberg/tickets2/common/main';
import {
  GlobalActions,
  GlobalSelectors,
  NavigationalActions,
  RouterSelectors,
} from '@remberg/ui-core/core';
import { Observable, combineLatest, map, tap, withLatestFrom } from 'rxjs';

export const tickets2GuardFn: CanActivateFn = (): Observable<boolean> => {
  const store = inject(Store);
  const logger = inject(LogService);

  const hasTickets2FeatureFlagEnabled$ = store.select(
    GlobalSelectors.selectHasFeature(FeatureFlagEnum.TICKETS_TEMPORARY),
  );
  const hasPermissionTicketsEnabled$ = store.select(
    GlobalSelectors.selectHasPermission(TicketsPermissionEnum.TICKETS_ENABLED),
  );

  return combineLatest([hasTickets2FeatureFlagEnabled$, hasPermissionTicketsEnabled$]).pipe(
    map(
      ([hasTickets2FeatureFlagEnabled, hasPermissionTicketsEnabled]) =>
        hasTickets2FeatureFlagEnabled && hasPermissionTicketsEnabled,
    ),
    withLatestFrom(store.select(RouterSelectors.selectUrl)),
    map(([hasAccess, currentUrl]) => {
      if (hasAccess) return true;
      logger.info()(`Tickets2 Guard: Redirecting from ${currentUrl ?? '/'}`);
      return false;
    }),
    tap((canActivateRoute) => {
      if (!canActivateRoute) {
        store.dispatch(
          GlobalActions.showToaster({
            toasterType: 'warning',
            message: $localize`:@@noTickets2FeatureAccess:Tickets2 feature access is not granted.`,
            title: '',
          }),
        );
        store.dispatch(NavigationalActions.goToAuthenticatedDefaultPage());
      }
    }),
  );
};
