import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EmptyListType, MultiSelectFunctions } from '@remberg/global/ui';

export interface BaseMultiselectComponentTranslations {
  title: string;
  emptyItemsText?: string;
}

@Component({
  template: '',
})
export abstract class BaseMultiselectComponent<T> {
  @Input() public multiselectControl = new FormControl<T[]>([], { nonNullable: true });
  @Input() public disabled = false;
  @Input() public readonly = false;
  @Input() public linkable = true;
  @Input() public isMultiselectOpenedOnInit = false;
  @Input() public isShowMoreFeatureEnabled = true;
  @Input() public isEmptyItemsTextVisible = true;
  @Input() public placeDropdownNextToButton = false;

  protected abstract titleIconSvg: string;
  protected abstract titleIcon: string;
  protected abstract translations: BaseMultiselectComponentTranslations;
  protected abstract emptyListType: EmptyListType;
  protected abstract functions: MultiSelectFunctions<T>;

  protected get matSelectPanelClass(): string {
    return this.placeDropdownNextToButton
      ? 'mdc-select-right-aligned-panel'
      : 'mdc-select-base-multiselect';
  }
}
