import { ErrorHandler, Injectable } from '@angular/core';
import { FileFormatEnum } from '@remberg/files/common/main';
import * as Sentry from '@sentry/angular';

const isImageFormatMissing = (error: any): boolean =>
  Object.values(FileFormatEnum).some((format) => error?.message?.includes(`size=${format}`));

// https://stackoverflow.com/questions/49198268/how-to-overcome-loading-chunk-failed-with-angular-lazy-loaded-modules

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private sentryErrorHandler: ErrorHandler;

  constructor() {
    this.sentryErrorHandler = Sentry.createErrorHandler({
      showDialog: false,
    });
  }

  public handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    // eslint-disable-next-line no-console
    console.log('Global Error Handler Log: ' + error.message);

    if (chunkFailedMessage.test(error.message)) {
      // eslint-disable-next-line no-console
      console.log('Global error handler refreshing the page due to chunk loading failure.');
      window.location.reload();
    } else if (isImageFormatMissing(error)) {
      // eslint-disable-next-line no-console
      console.log('Image format are missing.');
    } else if (error?.message?.includes('"StatusBar" plugin is not implemented on web')) {
      // eslint-disable-next-line no-console
      console.log('Ignoring error.');
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
      // Potential fix for: https://github.com/getsentry/sentry-javascript/issues/2292
      const exception = error.error || error.message || error.originalError || error;
      // Fallback: We pass the error to Sentry for further analysis.
      this.sentryErrorHandler.handleError(exception);
    }
  }
}
