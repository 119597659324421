import { Injectable } from '@angular/core';
import { BaseAskAiOverlayState, BaseAskAiOverlayStore } from '@remberg/ai/ui/ai-shared-base';

const INITIAL_ASK_AI_STATE: BaseAskAiOverlayState = { isInitialized: false };

@Injectable()
export class AskAiOverlayStore extends BaseAskAiOverlayStore<BaseAskAiOverlayState> {
  public override readonly initialize$ = this.updater(
    (state, id?: string): BaseAskAiOverlayState => ({
      ...state,
      isInitialized: true,
      id,
    }),
  );

  constructor() {
    super(INITIAL_ASK_AI_STATE);
  }
}
