import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TrackingEventEnum } from '@remberg/analytics/common/main';
import { DashboardsPermissionsEnum } from '@remberg/dashboards/common/main';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { MainRoutes, PublicRoutes, RouteDataPropertyEnum } from '@remberg/global/ui';
import {
  ActivationNavigationGuardService,
  AuthGuardService,
  DevelopmentGuardService,
  FileDriveGuard,
  FormsGuard,
  InitializationGuard,
  LoginNavigationGuardService,
  MaintenancePlansGuard,
  MaintenancePlansRouteDataPropertyEnum,
  NonRembergGuardService,
  NotFoundGuardService,
  OemGuard,
  RembergGuardService,
  TenantNavigationGuardService,
  TicketsGuard,
  WorkOrdersGuard,
  assetsGuardFn,
  forceRerenderResolver,
  logoutGuardFn,
  partsGuardFn,
  tasksGuardFn,
  tickets2GuardFn,
  trackingRedirectGuardFn,
  workOrders2GuardFn,
  workOrdersRedirectGuardFn,
} from '@remberg/ui-core/core';
import { generateChildrenMatchedByAccess } from '@remberg/ui-core/shared-main';
import { InterfaceComponent } from './core/interface/interface.component';
import { LandingPortalComponent } from './core/interface/landing-portal/landing-portal.component';
import { LandingComponent } from './core/interface/landing/landing.component';
import { ActivationComponent } from './pages/activation/activation.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PasswordExpirationComponent } from './pages/password-expiration/password-expiration.component';
import { SetpwdComponent } from './pages/setpwd/setpwd.component';
import { TenantLoginComponent } from './pages/tenant-login/tenant-login.component';
import { UpdateRequiredComponent } from './pages/update-required/update-required.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';

const tenantRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: PageNotFoundComponent, // this component will never be activated
  },
  {
    path: MainRoutes.ASSETS,
    loadChildren: () =>
      import('@remberg/assets/ui/assets-shell').then((m) => m.AssetsShellRoutingModule),
    canActivate: [assetsGuardFn],
    canActivateChild: [assetsGuardFn],
  },
  {
    path: MainRoutes.SERVICE_CASES,
    loadChildren: () => import('@remberg/tickets/ui/main').then((m) => m.CasesModule),
    canActivate: [TicketsGuard],
    canActivateChild: [TicketsGuard],
  },
  {
    path: MainRoutes.TICKETS2,
    loadChildren: () => import('@remberg/tickets2/ui/main').then((m) => m.Tickets2Module),
    canActivate: [tickets2GuardFn],
    canActivateChild: [tickets2GuardFn],
  },
  {
    path: MainRoutes.TASKS,
    loadChildren: () => import('@remberg/tasks/ui').then((m) => m.TasksModule),
    canActivate: [tasksGuardFn],
    canActivateChild: [tasksGuardFn],
  },
  {
    path: MainRoutes.PARTS,
    loadChildren: () => import('@remberg/parts/ui/main').then((m) => m.PartsModule),
    canActivate: [partsGuardFn],
    canActivateChild: [partsGuardFn],
  },
  {
    path: MainRoutes.CONTACTS,
    loadChildren: () =>
      import('@remberg/crm/ui/contacts-shell').then((m) => m.ContactsShellRoutingModule),
  },
  {
    path: MainRoutes.ORGANIZATIONS,
    canActivate: [OemGuard],
    loadChildren: () =>
      import('@remberg/crm/ui/organizations-main').then((m) => m.OrganizationsModule),
  },
  {
    path: MainRoutes.FORMS,
    loadChildren: () => import('@remberg/forms/ui/main').then((m) => m.RembergFormsModule),
    canActivate: [FormsGuard],
    canActivateChild: [FormsGuard],
  },
  // This route is necessary to redirect from the old forms
  // here users might have old one saved as the default web page.
  // TODO: Consider removing this in future.
  {
    path: MainRoutes.FORMS2,
    data: {
      [RouteDataPropertyEnum.TRACKING_EVENT]: TrackingEventEnum.FORMS2_REDIRECT,
    },
    canActivate: [
      trackingRedirectGuardFn({
        redirectUrl: MainRoutes.FORMS,
      }),
    ],
    children: [{ path: '**', children: [] }],
  },
  {
    path: MainRoutes.FILE_DRIVE,
    loadChildren: () => import('@remberg/files/ui/drive-files-main').then((m) => m.FileDriveModule),
    canActivate: [FileDriveGuard],
    canActivateChild: [FileDriveGuard],
  },
  ...generateChildrenMatchedByAccess({
    path: MainRoutes.DASHBOARD,
    targetRoute: {
      loadChildren: () => import('@remberg/dashboards/ui').then((m) => m.DashboardsModule),
    },
    featureFlag: FeatureFlagEnum.DASHBOARDS,
    permissions: [DashboardsPermissionsEnum.DASHBOARDS_ENABLED],
  }),
  {
    path: MainRoutes.WORK_ORDERS,
    loadChildren: () =>
      import('./modules/work-orders/work-orders.module').then((m) => m.WorkOrdersModule),
    canActivate: [workOrdersRedirectGuardFn, WorkOrdersGuard],
    canActivateChild: [workOrdersRedirectGuardFn, WorkOrdersGuard],
  },
  {
    path: `${MainRoutes.WORK_ORDERS_2}/plans`,
    loadChildren: () =>
      import('@remberg/maintenance-plans/ui/main').then((m) => m.MaintenancePlansModule),
    canActivate: [MaintenancePlansGuard],
    canActivateChild: [MaintenancePlansGuard],
    data: {
      [MaintenancePlansRouteDataPropertyEnum.IS_ACCESSING_FROM_WORK_ORDERS_2]: true,
    },
  },
  {
    path: MainRoutes.WORK_ORDERS_2,
    loadChildren: () => import('@remberg/work-orders/ui/main').then((m) => m.WorkOrdersModule),
    canActivate: [workOrders2GuardFn],
    canActivateChild: [workOrders2GuardFn],
  },
  ...generateChildrenMatchedByAccess({
    path: MainRoutes.MAP,
    targetRoute: {
      loadChildren: () => import('@remberg/ui-core/global-map').then((m) => m.GlobalMapModule),
    },
    featureFlag: FeatureFlagEnum.GLOBAL_MAP,
  }),
  {
    path: MainRoutes.SETTINGS,
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [NonRembergGuardService],
    canActivateChild: [NonRembergGuardService],
  },
  {
    path: MainRoutes.QRCODE,
    loadChildren: () => import('@remberg/assets/ui/qr-codes-main').then((m) => m.QRCodesModule),
    canActivate: [NonRembergGuardService],
  },
];

const loggedInRoutes: Routes = [
  {
    path: 'center',
    data: {
      [RouteDataPropertyEnum.TRACKING_EVENT]: TrackingEventEnum.CENTER_REDIRECT,
    },
    canActivate: [
      trackingRedirectGuardFn({
        redirectUrl: '/',
      }),
    ],
    children: [{ path: '**', children: [] }],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('@remberg/users/ui/user-profile-main').then((m) => m.UserProfileModule),
  },
  {
    path: 'about',
    loadChildren: () => import('@remberg/ui-core/about-main').then((m) => m.AboutMainModule),
  },
  {
    path: `${MainRoutes.USER}/:id`,
    pathMatch: 'full',
    data: {
      [RouteDataPropertyEnum.TRACKING_EVENT]: TrackingEventEnum.CONTACT_PREVIEW_REDIRECT,
    },
    canActivate: [
      trackingRedirectGuardFn({
        redirectUrl: `${MainRoutes.CONTACTS}/preview`,
      }),
    ],
    children: [],
  },
  {
    path: 'remberg',
    loadChildren: () => import('@remberg/admin/ui/main').then((m) => m.AdminModule),
    canActivate: [RembergGuardService],
    canActivateChild: [RembergGuardService],
  },
  {
    path: 'remberg-ui',
    loadChildren: () => import('@remberg/ui-core/remberg-ui').then((m) => m.RembergUiModule),
    canActivate: [DevelopmentGuardService],
    canActivateChild: [DevelopmentGuardService],
  },
  {
    path: '',
    children: tenantRoutes,
    canActivate: [NonRembergGuardService],
    canActivateChild: [NonRembergGuardService],
  },
];

const landingPortalRoutes: Routes = [
  {
    path: '',
    redirectTo: '/portal/welcome',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginNavigationGuardService],
    canActivateChild: [LoginNavigationGuardService],
  },
  // Wojciech: basic support for testing /portal/ features on preview env
  {
    path: 'welcome',
    component: TenantLoginComponent,
    canActivate: [TenantNavigationGuardService],
    canActivateChild: [TenantNavigationGuardService],
  },
  { path: 'forgot', component: ForgotComponent, canActivate: [ActivationNavigationGuardService] },
];

const landingRoutes: Routes = [
  {
    path: 'welcome',
    component: TenantLoginComponent,
    canActivate: [TenantNavigationGuardService],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginNavigationGuardService],
  },
  {
    path: 'login/password',
    component: PasswordExpirationComponent,
    canActivate: [AuthGuardService],
  },
  { path: 'forgot', component: ForgotComponent, canActivate: [ActivationNavigationGuardService] },
  { path: 'setpwd', component: SetpwdComponent, canActivate: [ActivationNavigationGuardService] },
  {
    path: 'activation',
    component: ActivationComponent,
    canActivate: [ActivationNavigationGuardService],
  },
  { path: 'verifyEmail', component: VerifyEmailComponent },
  {
    path: 'qrcode/:id',
    loadChildren: () =>
      import('@remberg/assets/ui/qr-codes-main-public').then((m) => m.QRCodesPublicModule),
  },
  {
    path: 'logout',
    canActivate: [logoutGuardFn],
    children: [],
  },
];

const publicRoutes: Routes = [
  {
    path: PublicRoutes.ASSETS,
    loadChildren: () =>
      import('@remberg/assets/ui/assets-main-public').then((m) => m.AssetsPublicModule),
  },
  // TODO: supporting both servicecases and request for now
  // but should be single after public tickets routes are unified
  {
    path: PublicRoutes.REQUEST,
    loadChildren: () =>
      import('@remberg/tickets/ui/tickets-main-public').then((m) => m.TicketsPublicModule),
  },
  {
    path: PublicRoutes.TICKETS,
    loadChildren: () =>
      import('@remberg/tickets/ui/tickets-main-public').then((m) => m.TicketsPublicModule),
  },
  {
    path: '**',
    redirectTo: '/portal/login',
  },
];

const routes: Routes = [
  {
    path: '',
    redirectTo: '/welcome',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LandingComponent,
    children: landingRoutes,
  },
  {
    path: MainRoutes.PUBLIC,
    children: publicRoutes,
  },
  {
    path: 'portal',
    component: LandingPortalComponent,
    children: landingPortalRoutes,
  },
  {
    path: '',
    component: InterfaceComponent,
    children: loggedInRoutes,
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
  },
  // Utility Routes
  { path: 'update-required', component: UpdateRequiredComponent },
  { path: 'notfound', pathMatch: 'full', component: PageNotFoundComponent },
  // catch all remaining routes (logged in)
  {
    path: '**',
    canActivate: [NotFoundGuardService],
    component: PageNotFoundComponent,
    pathMatch: 'full',
  },
  // catch all remaining routes (not logged in)
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

// in order to perform mobile migrations before any route is loaded,
// we must have a parent rootRoute with a guard that delays further navigation until the
// migration is complete. It also takes care of the appState initialization
const rootRoute: Routes = [
  {
    path: '',
    canActivate: [InitializationGuard],
    children: routes,
    resolve: { forceRerender: forceRerenderResolver },
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(rootRoute, {
      onSameUrlNavigation: 'reload',
      enableTracing: false,
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
