import { HttpClient, HttpHeaders, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AdvancedFilter } from '@remberg/advanced-filters/common/main';
import {
  Asset,
  AssetPublic,
  AssetQuanosLink,
  AssetsAddUserGroupBody,
  AssetsClearUserGroupBody,
  AssetsCreateOneBody,
  AssetsFilterEnum,
  AssetsFindManyByIdsBody,
  AssetsFindManyForMapQuery,
  AssetsFindManyForMapResponse,
  AssetsFindManyQuery,
  AssetsFindManyResponse,
  AssetsFindOneQuery,
  AssetsPopulateEnum,
  AssetsUniquenessCheckBody,
  AssetsUpdateOneBody,
} from '@remberg/assets/common/main';
import {
  API_URL_PLACEHOLDER,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  filterEmptyProps,
  filterNullOrUndefinedProps,
} from '@remberg/global/ui';
import { Observable, catchError, from, map, of } from 'rxjs';
import { AssetPopulated } from './asset-populated.interface';
import {
  ASSETS_NEW_OFFLINE_SERVICE,
  AssetsNewOfflineServiceInterface,
} from './assets-new.offline.service.interface';
import { AssetsOfflineAdvancedFilterConfigKeys } from './assets.definitions';

@Injectable({
  providedIn: 'root',
})
export class AssetsNewService {
  public readonly assetsUrl = `${API_URL_PLACEHOLDER}/assets/v1`;

  constructor(
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    @Inject(ASSETS_NEW_OFFLINE_SERVICE)
    private readonly assetsOfflineService: AssetsNewOfflineServiceInterface,
    private readonly httpClient: HttpClient,
  ) {}

  public findOnePopulated(assetId: string): Observable<AssetPopulated> {
    const params = new HttpParams({
      fromObject: filterNullOrUndefinedProps({
        populate: JSON.stringify(Object.values(AssetsPopulateEnum)),
      }),
    });
    return this.httpClient.get<AssetPopulated>(`${this.assetsUrl}/${assetId}`, { params });
  }

  public findOne(assetId: string, query?: AssetsFindOneQuery): Observable<Asset> {
    const isOnline = this.connectivityService.getConnected();
    if (!isOnline) {
      let populate: Record<string, boolean> | undefined;
      if (query?.populate) {
        populate = Object.fromEntries(query?.populate.map((value) => [value, true]));
      }
      return from(this.assetsOfflineService.findOne(assetId, populate));
    }

    const params = new HttpParams({
      fromObject: filterNullOrUndefinedProps({
        ...query,
        ...(query?.populate && { populate: JSON.stringify(query.populate) }),
      }),
    });
    return this.httpClient.get<Asset>(`${this.assetsUrl}/${assetId}`, { params });
  }

  public findManyByIds(body: AssetsFindManyByIdsBody): Observable<Asset[]> {
    const isOnline = this.connectivityService.getConnected();
    if (!isOnline) {
      return from(this.assetsOfflineService.findManyByIds(body.assetIds));
    }
    return this.httpClient.put<Asset[]>(this.assetsUrl, body);
  }

  public findMany({
    filterObject,
    staticFilters,
    populate,
    ...queryParams
  }: AssetsFindManyQuery): Observable<AssetsFindManyResponse> {
    const isOnline = this.connectivityService.getConnected();
    if (!isOnline) {
      const { limit, page, sortField, sortDirection, search } = queryParams;
      const filters = filterObject?.filters.filter(
        (filter) => filter.identifier !== AssetsFilterEnum.RELATED_PART,
      ) as AdvancedFilter<AssetsOfflineAdvancedFilterConfigKeys>[] | undefined;

      const offlineStaticFilters = staticFilters?.filter(
        (filter) => filter.identifier !== AssetsFilterEnum.RELATED_PART,
      ) as AdvancedFilter<AssetsOfflineAdvancedFilterConfigKeys>[] | undefined;

      return from(
        this.assetsOfflineService.findMany({
          limit,
          offset: page,
          sortColumn: sortField,
          sortDirection,
          searchValue: search,
          staticFilters: offlineStaticFilters,
          ...(filters &&
            filterObject && {
              filterQuery: {
                filters,
                concatOperator: filterObject.concatOperator,
              },
            }),
          ...(populate && {
            populate: Object.fromEntries(populate.map((value) => [value, true])),
          }),
        }),
      );
    }
    const params = new HttpParams({
      fromObject: {
        ...filterEmptyProps(queryParams),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(staticFilters && { staticFilters: JSON.stringify(staticFilters) }),
        ...(populate && { populate: JSON.stringify(populate) }),
      },
    });
    return this.httpClient.get<AssetsFindManyResponse>(this.assetsUrl, { params });
  }

  public findManyForMap({
    boundingBox,
    filterObject,
    staticFilters,
    search,
  }: AssetsFindManyForMapQuery): Observable<AssetsFindManyForMapResponse> {
    const params = new HttpParams({
      fromObject: {
        ...(search && { search }),
        ...(boundingBox && { boundingBox: JSON.stringify(boundingBox) }),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(staticFilters && { staticFilters: JSON.stringify(staticFilters) }),
      },
    });
    return this.httpClient.get<AssetsFindManyForMapResponse>(`${this.assetsUrl}/map`, { params });
  }

  public findOnePublic(assetId: string): Observable<AssetPublic> {
    return this.httpClient.get<AssetPublic>(`${this.assetsUrl}/public/${assetId}`);
  }

  public createOne(body: AssetsCreateOneBody): Observable<Asset> {
    return this.httpClient.post<Asset>(this.assetsUrl, body);
  }

  public updateOne(assetId: string, body: AssetsUpdateOneBody): Observable<Asset> {
    return this.httpClient.patch<Asset>(`${this.assetsUrl}/${assetId}`, body);
  }

  public deleteOne(assetId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.assetsUrl}/${assetId}`);
  }

  public serialNumberExists(body: AssetsUniquenessCheckBody): Observable<boolean> {
    return this.httpClient.post<void>(`${this.assetsUrl}/uniqueness`, body).pipe(
      map(() => false),
      catchError((error) => of(error.status === HttpStatusCode.Conflict)),
    );
  }

  public addUserGroup(body: AssetsAddUserGroupBody): Observable<void> {
    return this.httpClient.patch<void>(`${this.assetsUrl}/usergroups/add`, body);
  }

  public clearUserGroup(body: AssetsClearUserGroupBody): Observable<void> {
    return this.httpClient.patch<void>(`${this.assetsUrl}/usergroups/clear`, body);
  }

  public generateQuanosLink(assetsId: string): Observable<AssetQuanosLink> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.httpClient.get<AssetQuanosLink>(
      `${this.assetsUrl}/${assetsId}/quanos`,
      httpOptions,
    );
  }
}
