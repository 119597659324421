import { combineReducers } from '@ngrx/store';
import { INITIAL_ASK_AI_STATE, askAiReducer } from './ask-ai';
import { RootGlobalAskAiState } from './global-ask-ai.definitions';

export const globalAskAiReducer = combineReducers<RootGlobalAskAiState>(
  {
    askAi: askAiReducer,
  },
  {
    askAi: INITIAL_ASK_AI_STATE,
  },
);
