import { ContactBasic, OrganizationBasic } from '@remberg/crm/common/base';
import {
  CustomPropertyTypesEnum,
  CustomPropertyValue,
} from '@remberg/custom-properties/common/main';
import { CreationTypeEnum, createMapper } from '@remberg/global/common/core';
import { Asset } from '../../assets';
import { AssetCustomProperty, AssetCustomPropertyValueLegacy, Product } from '../definitions';

const mapCPValueToLegacyValue = (
  customPropertyValue: CustomPropertyValue<false>,
): string | string[] | number | boolean => {
  switch (customPropertyValue.propertyType) {
    case CustomPropertyTypesEnum.REMBERG_ASSET_SELECT:
    case CustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT:
    case CustomPropertyTypesEnum.REMBERG_CONTACT_SELECT:
      return customPropertyValue.value?.[0];
    default:
      return customPropertyValue.value;
  }
};

function mapCustomPropertyToLegacyCustomProperty(
  customPropertyValues: CustomPropertyValue[] | CustomPropertyValue<true>[],
): AssetCustomPropertyValueLegacy[] {
  return customPropertyValues?.map((customProperty) => ({
    // TODO AssetsRework For the table compatibility is enough to have only the property id. For the detail page the new shape will be used.
    property: customProperty.customPropertyId as unknown as AssetCustomProperty,
    value: mapCPValueToLegacyValue(customProperty),
    propertyId: parseInt(customProperty.externalReference),
  }));
}

function mapOneAssetToProduct(asset: Asset): Product {
  return {
    _id: asset._id,
    serialNumber: asset.serialNumber,
    customerOrganization: asset.relatedOrganizations?.[0]
      ? (asset.relatedOrganizations?.[0] as OrganizationBasic)
      : asset.relatedOrganizationIds?.[0],
    tenantId: asset.tenantId,
    customerContact: asset.relatedContacts?.[0]
      ? (asset.relatedContacts?.[0] as ContactBasic)
      : asset.relatedContactIds?.[0],
    manufacturerContact: asset.tenantOwnerContact
      ? (asset.tenantOwnerContact as ContactBasic)
      : asset.tenantOwnerContactId,
    productType: {
      _id: asset.assetTypeId,
      tenantId: asset.tenantId,
      label: asset.assetTypeLabel,
      image: asset.assetTypeImageId,
    },
    productTypeName: asset.assetTypeLabel,
    qrcode: asset.relatedQRCodeIds?.[0],
    location: asset.location,
    userGroups: asset.userGroupIds,
    lastModified: asset.updatedAt,
    createdAt: asset.createdAt,
    status: asset.status,
    assetHierarchy: asset.ancestorIds.length ? `,${asset.ancestorIds.join(',')},` : undefined,
    parentAsset: asset.parent
      ? {
          _id: asset.parent._id,
          serialNumber: asset.parent.serialNumber,
          productType: {
            _id: asset.parent.assetTypeId,
            label: asset.parent.assetTypeName,
            image: asset.parent.assetTypeImageId,
            tenantId: asset.tenantId,
          },
          productTypeName: asset.parent.assetTypeName,
        }
      : undefined,
    activities: [],
    customProperties: mapCustomPropertyToLegacyCustomProperty(asset.customPropertyValues),
    createContext: {
      source: asset.createdBy ? (asset.createdBy as ContactBasic) : asset.createdById,
      time: new Date().toISOString() as unknown as Date,
      type: CreationTypeEnum.USER,
      context: '',
    },
  };
}

export const mapAssetToProduct = createMapper(mapOneAssetToProduct);
