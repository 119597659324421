import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { MainRoutes, WorkOrderRightsEnum } from '@remberg/global/ui';
import { WorkOrderPermissionsEnum } from '@remberg/work-orders/common/main';
import { UserRightsService } from '../../services/user-rights.service';

export const workOrdersRedirectGuardFn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): MaybeAsync<GuardResult> => {
  const userRightsService = inject(UserRightsService);
  const router = inject(Router);

  const isWorkOrdersOldFeatureAccessible = userRightsService.getPermission(
    WorkOrderRightsEnum.WORK_ORDER_ENABLED,
  );
  const isWorkOrders2FeatureAccessible = userRightsService.getPermission(
    WorkOrderPermissionsEnum.WORK_ORDERS_ENABLED,
  );

  if (!isWorkOrdersOldFeatureAccessible && isWorkOrders2FeatureAccessible) {
    const newUrl = state.url.replace(`/${MainRoutes.WORK_ORDERS}`, `/${MainRoutes.WORK_ORDERS_2}`);
    const newUrlTree = router.parseUrl(newUrl);

    return newUrlTree;
  }

  // Always return true to let the next guard decide what to do
  return true;
};
