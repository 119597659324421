import { Params } from '@angular/router';
import { entriesOf } from '@remberg/global/common/core';

export function getEventDataMap(
  trackingEventParamMap: Record<string, string>,
  params: Params,
): Record<string, string> {
  return Object.fromEntries(
    entriesOf(trackingEventParamMap).map(([trackingEventProperty, paramName]) => [
      trackingEventProperty,
      params[paramName],
    ]),
  );
}
