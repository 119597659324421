import { createSelector } from '@ngrx/store';
import { AiPermissionsEnum } from '@remberg/ai/common/main';
import { ASSET_ROUTE_ID } from '@remberg/assets/ui/definitions';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { IsPageActiveEnum } from '@remberg/global/ui';
import { TICKET2_ID_PARAM } from '@remberg/tickets2/ui/shared-base';
import { GlobalSelectors, RouterSelectors } from '@remberg/ui-core/core';
import { selectGlobalAskAiSharedState } from '../global-ask-ai.definitions';

const selectState = createSelector(selectGlobalAskAiSharedState, (state) => state.askAi);
const selectIsSidebarOpen = createSelector(selectState, (state) => state.isSidebarOpen);
const selectActiveFeature = createSelector(selectState, (state) => state.activeFeature);
const selectId = createSelector(selectState, (state) => state.id);

const selectIsAskAiEnabledForRoute = (isPageActive: IsPageActiveEnum) =>
  createSelector(
    selectIsAiCopilotEnabled,
    RouterSelectors.selectRouteData,
    (hasAccess, routeData) => hasAccess && routeData?.[isPageActive],
  );

const selectIsAiCopilotEnabled = GlobalSelectors.selectHasAccess(
  [FeatureFlagEnum.AI_COPILOT],
  [AiPermissionsEnum.AI_COPILOT_ENABLED],
);

const selectIsAskAiButtonVisible = createSelector(
  selectIsAiCopilotEnabled,
  GlobalSelectors.selectIsIonic,
  (isAiCopilotEnabled, isIonic) => isAiCopilotEnabled && !isIonic,
);

const selectIsAskFileGlobalButtonVisible = createSelector(
  selectIsAskAiEnabledForRoute(IsPageActiveEnum.IS_FILES_OVERVIEW_PAGE),
  RouterSelectors.selectQueryParams,
  (isEnabled, queryParams) => isEnabled && !!queryParams?.['knowledgeBaseFileId'],
);
const selectIsAskXGlobalButtonVisible = selectIsAskAiEnabledForRoute(
  IsPageActiveEnum.IS_ASSET_DETAIL_PAGE,
);

const selectIsAskTicketsGlobalButtonVisible = createSelector(
  selectIsAskAiEnabledForRoute(IsPageActiveEnum.IS_TICKET2_DETAIL_PAGE),
  selectIsAskAiEnabledForRoute(IsPageActiveEnum.IS_CASE_DETAIL_PAGE),
  (isTickets2DetailPageActive, isCasesDetailPageActive) =>
    isTickets2DetailPageActive || isCasesDetailPageActive,
);

const selectIsAskAiWithContextVisible = createSelector(
  selectIsAskXGlobalButtonVisible,
  selectIsAskFileGlobalButtonVisible,
  selectIsAskTicketsGlobalButtonVisible,
  (isAskFileVisible, isAskXVisible, isAskTicketsVisible) =>
    isAskXVisible || isAskFileVisible || isAskTicketsVisible,
);

const selectAssetIdRouteParam = createSelector(
  RouterSelectors.selectRouteParams,
  GlobalSelectors.selectIsAssetsTemporaryEnabled,
  (params, isAssetsTemporaryEnabled): string =>
    isAssetsTemporaryEnabled ? params[ASSET_ROUTE_ID] : params[`id`],
);

const selectTicketsIdRouteParam = createSelector(
  RouterSelectors.selectRouteParams,
  GlobalSelectors.selectHasFeature(FeatureFlagEnum.TICKETS_TEMPORARY),
  (params, isTicketsTemporaryEnabled): string =>
    isTicketsTemporaryEnabled ? params[TICKET2_ID_PARAM] : params[`id`],
);

const selectKnowledgeBaseFileIdQueryParam = createSelector(
  RouterSelectors.selectQueryParams,
  (queryParams): string => queryParams['knowledgeBaseFileId'],
);

export const AskAiSelectors = {
  selectIsAiCopilotEnabled,
  selectIsSidebarOpen,
  selectIsAskAiButtonVisible,
  selectIsAskXGlobalButtonVisible,
  selectIsAskFileGlobalButtonVisible,
  selectAssetIdRouteParam,
  selectKnowledgeBaseFileIdQueryParam,
  selectActiveFeature,
  selectId,
  selectIsAskAiWithContextVisible,
  selectIsAskTicketsGlobalButtonVisible,
  selectTicketsIdRouteParam,
};
