import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PrefilledOverlayFeaturesEnum } from '@remberg/ai/ui/ai-shared-base';

export const AskAiActions = createActionGroup({
  source: 'AskAiActions',
  events: {
    'Open Sidebar': props<{ id: string; feature: PrefilledOverlayFeaturesEnum }>(),
    'Open Sidebar From Global Context': emptyProps(),
    'Sidebar Opened': props<{ id?: string; activeFeature?: PrefilledOverlayFeaturesEnum }>(),
    'Sidebar Closed': emptyProps(),
    Destroyed: emptyProps(),
  },
});
