import { Injectable } from '@angular/core';
import { Actions, EffectNotification, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PrefilledOverlayFeaturesEnum } from '@remberg/ai/ui/ai-shared-base';
import { isObjectID } from '@remberg/global/ui';
import { Observable, filter, map, takeUntil, withLatestFrom } from 'rxjs';
import { RootAiSharedGlobalAskAiState } from '../global-ask-ai.definitions';
import { AskAiActions } from './ask-ai.actions';
import { AskAiSelectors } from './ask-ai.selectors';

@Injectable()
export class AskAiEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootAiSharedGlobalAskAiState>,
  ) {}

  public readonly openSidebarForAskX$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AskAiActions.openSidebarFromGlobalContext),
      withLatestFrom(
        this.store.select(AskAiSelectors.selectIsAskXGlobalButtonVisible),
        this.store.select(AskAiSelectors.selectIsSidebarOpen),
        this.store.select(AskAiSelectors.selectAssetIdRouteParam).pipe(filter(isObjectID)),
      ),
      filter(([, isAskAiButtonVisible, isSidebarOpen]) => isAskAiButtonVisible && !isSidebarOpen),
      map(([, , , id]) =>
        AskAiActions.sidebarOpened({ id, activeFeature: PrefilledOverlayFeaturesEnum.ASSETS }),
      ),
    ),
  );

  public readonly openSidebarForFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AskAiActions.openSidebarFromGlobalContext),
      withLatestFrom(
        this.store.select(AskAiSelectors.selectIsAskFileGlobalButtonVisible),
        this.store.select(AskAiSelectors.selectIsSidebarOpen),
        this.store
          .select(AskAiSelectors.selectKnowledgeBaseFileIdQueryParam)
          .pipe(filter(isObjectID)),
      ),
      filter(([, isAskAiButtonVisible, isSidebarOpen]) => !!isAskAiButtonVisible && !isSidebarOpen),
      map(([, , , id]) =>
        AskAiActions.sidebarOpened({ id, activeFeature: PrefilledOverlayFeaturesEnum.FILES }),
      ),
    ),
  );

  public readonly openSidebarForAskTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AskAiActions.openSidebarFromGlobalContext),
      withLatestFrom(
        this.store.select(AskAiSelectors.selectIsAskTicketsGlobalButtonVisible),
        this.store.select(AskAiSelectors.selectIsSidebarOpen),
        this.store.select(AskAiSelectors.selectTicketsIdRouteParam).pipe(filter(isObjectID)),
      ),
      filter(([, isAskAiButtonVisible, isSidebarOpen]) => isAskAiButtonVisible && !isSidebarOpen),
      map(([, , , id]) =>
        AskAiActions.sidebarOpened({ id, activeFeature: PrefilledOverlayFeaturesEnum.TICKETS }),
      ),
    ),
  );

  public readonly openSidebarForGeneralFeature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AskAiActions.openSidebarFromGlobalContext),
      withLatestFrom(
        this.store.select(AskAiSelectors.selectIsAskAiWithContextVisible),
        this.store.select(AskAiSelectors.selectIsSidebarOpen),
      ),
      filter(
        ([, isAskAiWithContextVisible, isSidebarOpen]) =>
          !isAskAiWithContextVisible && !isSidebarOpen,
      ),
      map(() => AskAiActions.sidebarOpened({})),
    ),
  );

  public readonly openSidebarForFeature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AskAiActions.openSidebar),
      withLatestFrom(this.store.select(AskAiSelectors.selectIsSidebarOpen)),
      filter(([, isSidebarOpen]) => !isSidebarOpen),
      map(([{ id, feature: activeFeature }]) => AskAiActions.sidebarOpened({ id, activeFeature })),
    ),
  );

  public ngrxOnRunEffects(
    resolvedEffects$: Observable<EffectNotification>,
  ): Observable<EffectNotification> {
    return resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(AskAiActions.destroyed))));
  }
}
