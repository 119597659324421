<div
  class="notification-popup-menu"
  [ngClass]="{ 'notification-popup-menu-modal': (layout.isXSmallView | async) }"
>
  <div
    fxLayout="row"
    class="notifications-header-row"
    [ngClass]="{ 'notifications-header-row-phone': (layout.isXSmallView | async) }"
  >
    <div class="mat-headline-5 notifications-headline" i18n="@@notifications">Notifications</div>
    <div class="notifications-header-button-group">
      <button mat-icon-button (click)="close()">
        <mat-icon class="close-notifications-icon">close</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="notifications?.length === 0" class="empty-notifications-wrapper">
    <app-empty-list [type]="'notifications'"></app-empty-list>
  </div>

  <div class="notifications-wrapper">
    <ng-container *ngIf="notifications && notifications.length">
      <div *ngFor="let notificationGroup of notifications?.slice()?.reverse()">
        <!-- Single Notifications -->
        <div
          *ngIf="notificationGroup && notificationGroup.notifications?.length === 1"
          class="notification-list-item notification-bottom-border"
          [ngClass]="{ 'is-read': isNotificationGroupRead(notificationGroup) }"
        >
          <button
            *ngIf="notificationGroup.notifications[0]; let notification"
            mat-menu-item
            (click)="
              markNotificationAsRead(notification._id, $event, false);
              redirectToNotificationLink(notification)
            "
            class="notification-list-item-button"
            data-test-id="notification"
          >
            <div class="notification-icon" *ngIf="getMatIconForNotification(notification) as icon">
              <div *ngIf="!notification.isRead" class="notification-indicator"></div>
              <mat-icon *ngIf="icon.isSvgIcon" [svgIcon]="icon.icon" />
              <mat-icon *ngIf="!icon.isSvgIcon"> {{ icon.icon }} </mat-icon>
            </div>
            <div class="notification-content">
              <div class="button-content-column">
                <div class="mat-subtitle-2" data-test-id="notification-headline">
                  {{ notification.headline }}
                </div>
                <div class="mat-subtitle-2 sub-line" data-test-id="notification-message">
                  {{ notification.message }}
                </div>
              </div>
              <div class="time-action-group">
                <div class="mat-subtitle-2 sub-line-time">
                  {{ dateTimeService.getElapsedTime(notification.timeStamp) }}
                </div>
              </div>
            </div>
            <div class="actions-container">
              <button
                mat-icon-button
                data-test-id="delete-notification"
                (click)="
                  removeNotifications({
                    notifications: [notification],
                    event: $event,
                    shouldCloseMenu: false,
                  })
                "
              >
                <mat-icon class="x-large-icon show-on-hover">delete</mat-icon>
              </button>
            </div>
          </button>
        </div>
        <!-- Group Notifications -->
        <div
          *ngIf="
            notificationGroup &&
            notificationGroup.notifications &&
            notificationGroup.notifications.length > 1
          "
          class="notification-list-item"
          [ngClass]="{
            'notification-bottom-border': !notificationGroup?.expanded,
            'is-read': isNotificationGroupRead(notificationGroup),
          }"
        >
          <div
            mat-menu-item
            class="notification-list-item-button"
            (click)="expandButton($event, notificationGroup)"
            *ngIf="notificationGroup.notifications[0]; let notification"
          >
            <div class="notification-icon" *ngIf="getMatIconForNotification(notification) as icon">
              <div *ngIf="!notification.isRead" class="notification-indicator"></div>
              <mat-icon *ngIf="icon.isSvgIcon" [svgIcon]="icon.icon" />
              <mat-icon *ngIf="!icon.isSvgIcon"> {{ icon.icon }} </mat-icon>
            </div>
            <div class="notification-content">
              <div fxLayout="row">
                <div class="button-content-column">
                  <div class="mat-subtitle-2">
                    {{ notification.headline }}
                  </div>
                  <div class="new-notification-button-group">
                    <div class="mat-subtitle-2 sub-line">
                      {{ notificationGroup?.notifications?.length }}
                      <ng-container i18n="@@newNotifications">new notifications</ng-container>
                    </div>
                  </div>
                </div>
                <div class="actions-container">
                  <button
                    mat-icon-button
                    (click)="
                      removeNotifications({
                        notifications: notificationGroup.notifications,
                        event: $event,
                      })
                    "
                  >
                    <mat-icon class="x-large-icon show-on-hover">delete</mat-icon>
                  </button>

                  <mat-icon>{{
                    notificationGroup?.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                  }}</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Expanded Group Notifications -->
        <div
          *ngIf="
            notificationGroup?.expanded && notificationGroup && notificationGroup.notifications
          "
          class="notification-group"
        >
          <div
            *ngFor="let singleNotification of notificationGroup?.notifications"
            class="notification-list-item"
            [ngClass]="{ 'is-read': singleNotification.isRead }"
          >
            <button
              mat-menu-item
              class="notification-list-item-button"
              data-test-id="notification"
              (click)="
                markNotificationAsRead(singleNotification._id, $event, false);
                redirectToNotificationLink(singleNotification)
              "
            >
              <div
                class="notification-icon"
                *ngIf="getMatIconForNotification(singleNotification) as icon"
              >
                <div *ngIf="!singleNotification.isRead" class="notification-indicator"></div>
                <mat-icon *ngIf="icon.isSvgIcon" [svgIcon]="icon.icon" />
                <mat-icon *ngIf="!icon.isSvgIcon"> {{ icon.icon }} </mat-icon>
              </div>
              <div class="notification-content">
                <div class="button-content-column">
                  <div class="mat-subtitle-2" data-test-id="notification-headline">
                    {{ singleNotification.headline }}
                  </div>
                  <div class="mat-subtitle-2 sub-line" data-test-id="notification-message">
                    {{ singleNotification.message }}
                  </div>
                </div>
                <div class="time-action-group">
                  <div class="mat-subtitle-2 sub-line-time">
                    {{ dateTimeService.getElapsedTime(singleNotification.timeStamp) }}
                  </div>
                </div>
              </div>
              <div class="actions-container">
                <button
                  mat-icon-button
                  (click)="
                    removeNotifications({
                      notifications: [singleNotification],
                      event: $event,
                      shouldCloseMenu: false,
                    })
                  "
                >
                  <mat-icon class="x-large-icon show-on-hover">delete</mat-icon>
                </button>
              </div>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="fill-vertical-remaining-space"></div>

  <div fxLayout="row" class="notifications-footer-row" *ngIf="notifications?.length">
    <button
      class="remove-all-button"
      mat-button
      (click)="removeAllNotifications($event)"
      i18n="@@removeAllNotifications"
    >
      Remove all notifications
    </button>
  </div>
</div>
