import { valuesOf } from '@remberg/global/common/core';
import { Ticket2StatusEnum } from '../enums';

export const COMPLETED_STATUSES = new Set<Ticket2StatusEnum>([
  Ticket2StatusEnum.CLOSED,
  Ticket2StatusEnum.SOLVED,
  Ticket2StatusEnum.MOVED,
]);

export const STATUSES_EXCLUDED_FROM_RESOLUTION_TIME = new Set<Ticket2StatusEnum>([
  ...COMPLETED_STATUSES,
  Ticket2StatusEnum.PENDING_EXTERNAL,
]);

export const STATUSES_INCLUDED_IN_TIME_TO_RESOLUTION: Ticket2StatusEnum[] = valuesOf(
  Ticket2StatusEnum,
).filter((status) => !STATUSES_EXCLUDED_FROM_RESOLUTION_TIME.has(status));
