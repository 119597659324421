import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IMAGE_FALLBACK_URL } from '@remberg/global/ui';
import { NavigationService, ServerFilePaths } from '@remberg/ui-core/core';

@Component({
  selector: 'app-preview-indicator',
  templateUrl: './preview-indicator.component.html',
  styleUrls: ['./preview-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewIndicatorComponent {
  /** label: main text input of indicator */
  @Input() public title?: string;

  /** icon: use material icon to show before indicator, thumbnail will overwrite it */
  @Input() public icon?: string;
  @Input() public materialIconsFilled: boolean = true;
  @Input() public isSvgIcon?: boolean;

  /** thumbnail: image of the preview, overwrites icon */
  @Input() public thumbnailId?: string;
  @Input() public thumbnailSource?: ServerFilePaths;

  /** Fallback to use in case the thumbnail could not be found. Use IMAGE_FALLBACK_URL set up in the app.config */
  @Input() public thumbnailFallback: string = IMAGE_FALLBACK_URL.unknown;

  /** isLink: underlines label and should be passed when clickFunction is added */
  @Input() public isLink: boolean = false;

  /** clickFunction: pass a function that is called on click */
  @Output() public onClick = new EventEmitter<MouseEvent>();

  /** linkUrl: if set, the text will be marked as a link and a click will navigate to the linkUrl */
  @Input() public linkUrl?: string;

  /** alwaysOpenInNewTab: if set to true together with a linkPath, the link will always be opened in a new tab/window */
  @Input() public alwaysOpenInNewTab?: boolean;

  /** hideLabel: adds the possibility to only work with the icon / image */
  @Input() public hideLabel: boolean = false;

  @Input() public boldLabel: boolean = false;

  @Input() public smallSize: boolean = false;

  @Input() protected isTooltipVisible = false;

  /** isBackdropOnHoverVisible: when enabled backdrop for chip is visible on hover */
  @Input() protected isBackdropOnHoverVisible = true;

  constructor(private navigationService: NavigationService) {}

  protected IMAGE_FALLBACK_URL = IMAGE_FALLBACK_URL;

  public handleClick(evt: MouseEvent): void {
    if (this.linkUrl) {
      this.navigationService.navigateWithNewTabOption(evt, this.linkUrl, this.alwaysOpenInNewTab);
      evt.stopPropagation();
    }
    this.onClick.emit(evt);
  }

  public handleMouseup(evt: MouseEvent): void {
    if (this.linkUrl) {
      this.navigationService.navigateWithNewTabOption(evt, this.linkUrl, this.alwaysOpenInNewTab);
      evt.stopPropagation();
    }
  }
}
