import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirectionEnum } from '@remberg/global/common/core';
import { API_URL_PLACEHOLDER, ApiResponse } from '@remberg/global/ui';
import {
  Task,
  TaskAdvancedFilterObject,
  TaskCreate,
  TaskSortFieldEnum,
  TaskUpdate,
} from '@remberg/tasks/common/main';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  private readonly TASKS_URL = `${API_URL_PLACEHOLDER}/tasks/v2`;

  constructor(private readonly http: HttpClient) {}

  public createOne(task: TaskCreate): Observable<Task> {
    return this.http.post<Task>(this.TASKS_URL, task);
  }

  public updateOne({ id, changes }: TaskUpdate): Observable<Task> {
    return this.http.patch<Task>(`${this.TASKS_URL}/${id}`, changes);
  }

  public deleteOne(taskId: string): Observable<void> {
    return this.http.delete<void>(`${this.TASKS_URL}/${taskId}`);
  }

  public getOne(taskId: string): Observable<Task> {
    return this.http.get<Task>(`${this.TASKS_URL}/${taskId}`);
  }

  public getMany({
    filterObject,
    pageIndex,
    pageSize,
    searchQuery,
    sortDirection,
    sortField,
    staticFilter,
  }: {
    filterObject?: TaskAdvancedFilterObject;
    pageIndex?: number;
    pageSize?: number;
    searchQuery?: string;
    sortDirection?: SortDirectionEnum;
    sortField?: TaskSortFieldEnum;
    staticFilter?: TaskAdvancedFilterObject;
  }): Observable<ApiResponse<Task[]>> {
    let params = new HttpParams();

    if (pageSize) params = params.set('limit', pageSize);
    if (pageIndex) params = params.set('page', pageIndex);
    if (searchQuery) params = params.set('search', searchQuery);
    if (filterObject) params = params.set('filterObject', JSON.stringify(filterObject));
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    if (sortField) params = params.set('sortField', sortField);
    if (staticFilter) params = params.set('staticFilter', JSON.stringify(staticFilter));

    return this.http.get<ApiResponse<Task[]>>(this.TASKS_URL, { params });
  }

  public getNumberOfTasks(staticFilter?: TaskAdvancedFilterObject): Observable<number> {
    let params = new HttpParams();
    if (staticFilter) params = params.set('staticFilter', JSON.stringify(staticFilter));
    return this.http.get<number>(`${this.TASKS_URL}/count`, { params });
  }
}
