import { TableColumnDisplayOptionEnum } from '@remberg/global/common/core';
import { TableColumnDisplayOption } from './table-columns';

export const DATETIME_DISPLAY_OPTIONS: TableColumnDisplayOption[] = [
  {
    value: TableColumnDisplayOptionEnum.EXACT,
    label: $localize`:@@exact:Exact`,
    tooltipDatetime: true,
  },
  {
    value: TableColumnDisplayOptionEnum.RELATIVE,
    label: $localize`:@@relative:Relative`,
    tooltip: $localize`:@@eg5MonthsAgo:E.g.: 5 months ago`,
  },
];

export const DATE_DISPLAY_OPTIONS: TableColumnDisplayOption[] = [
  {
    value: TableColumnDisplayOptionEnum.EXACT,
    label: $localize`:@@exact:Exact`,
    tooltipDate: true,
  },
  {
    value: TableColumnDisplayOptionEnum.RELATIVE,
    label: $localize`:@@relative:Relative`,
    tooltip: $localize`:@@eg5MonthsAgo:E.g.: 5 months ago`,
  },
];
