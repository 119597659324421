import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

const angularModules = [CommonModule];

// const ngrxModules = [];

// const rembergModules = [];

// const thirdPartyModules = [];

@NgModule({
  imports: [
    ...angularModules,
    // ...ngrxModules,
    // ...rembergModules,
    // ...thirdPartyModules,
  ],
  declarations: [],
  exports: [],
})
export class Tickets2SharedBaseModule {}
