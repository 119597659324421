export enum Ticket2StatusEnum {
  NEW = 'new', // Ticket is created by an external source (Eg. Email)
  OPEN = 'open',
  PENDING_INTERNAL = 'pendingInternal',
  PENDING_EXTERNAL = 'pendingExternal',
  SOLVED = 'solved',
  CLOSED = 'closed',
  MOVED = 'moved',
}

export enum Ticket2StatusPortalFiltersEnum {
  BEING_PROCESSED = 'beingProcessed',
  PENDING_EXTERNAL = 'pendingExternal',
  CLOSED_PORTAL = 'closedPortal',
  MOVED = 'moved',
}
