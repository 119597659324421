/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSelector, Selector } from '@ngrx/store';
import { SharedTablesState, SharedTableState } from './definitions';

export const createSharedTableSelectorsFor = <RootState, T extends { _id: string }, F, C>(
  rootStateSelector: Selector<RootState, SharedTablesState<T, F, C>>,
) => {
  const selectTableState = (
    id: string,
  ): Selector<RootState, SharedTableState<T, F, C> | undefined> =>
    createSelector(rootStateSelector, (state) => state[id]);

  const select =
    <R>(selector: Selector<SharedTableState<T, F, C> | undefined, R>) =>
    (id: string) =>
      createSelector(selectTableState(id), selector);

  return {
    selectTableState: selectTableState,

    selectTableIsInitialized: select((state) => !!state?.initialized),
    selectTableParams: select((state) => state?.params),
    selectTableIsLoading: select((state) => !!state?.loading),
    selectTableItemCount: select((state) => state?.count ?? 0),
    selectTableItems: select((state) => state?.items || []),
    selectTableSelectedItems: select((state) => state?.selectedItems),
    selectTableError: select((state) => state?.error),
    selectConfig: select((state) => state?.config),
  };
};
