import { Pipe, PipeTransform } from '@angular/core';
import { mapDurationInSecondsToDisplayString } from '@remberg/global/common/core';

@Pipe({
  name: 'durationInSecondsToDisplayString',
})
export class DurationInSecondsToDisplayStringPipe implements PipeTransform {
  public transform(seconds?: number | null, emptyResult = ''): string {
    return mapDurationInSecondsToDisplayString(seconds, emptyResult);
  }
}
