export enum AssetsPopulateEnum {
  PARENT = 'parent',
  RELATED_ORGANIZATIONS = 'relatedOrganizations',
  TENANT_OWNER_CONTACT = 'tenantOwnerContact',
  RELATED_CONTACTS = 'relatedContacts',
  CREATED_BY = 'createdBy',
  USER_GROUPS = 'userGroups',
  CUSTOM_PROPERTIES = 'customProperties',
  /**
   * @deprecated needed for mobile app <= 2.54.0 compatibility
   */
  MANUFACTURER_ORGANIZATION = 'manufacturerOrganization',
  /**
   * @deprecated needed for mobile app <= 2.54.0 compatibility
   */
  CUSTOMER_CONTACTS = 'customerContacts',
  /**
   * @deprecated needed for mobile app <= 2.54.0 compatibility
   */
  CUSTOMER_ORGANIZATION = 'customerOrganization',
}
