import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import {
  BaseAskAiOverlayState,
  BaseAskAiOverlayStore,
  RegisterAskAiStoreProviderService,
} from '@remberg/ai/ui/ai-shared-base';
import { AskAiOverlayComponent } from '@remberg/ai/ui/shared-ai-chat';
import { TransformToBase64Pipe } from '@remberg/global/ui';
import { first } from 'rxjs';
import { AskAiActions, AskAiSelectors, RootAiSharedGlobalAskAiState } from '../../../store';
import { AskAiOverlayStore } from './store';

@Component({
  selector: 'app-ask-ai-overlay-wrapper',
  templateUrl: './ask-ai-overlay-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AskAiOverlayComponent],
  standalone: true,
  providers: [
    TransformToBase64Pipe,
    {
      provide: BaseAskAiOverlayStore,
      useFactory: (
        store: Store<RootAiSharedGlobalAskAiState>,
        registerAskAiStoreProvider: RegisterAskAiStoreProviderService,
      ): ComponentStore<BaseAskAiOverlayState> => {
        let componentStore = new AskAiOverlayStore();

        store
          .select(AskAiSelectors.selectActiveFeature)
          .pipe(first())
          .subscribe((activeFeature) => {
            componentStore =
              (activeFeature &&
                registerAskAiStoreProvider.registeredStoreProviders[activeFeature]) ??
              componentStore;
          });
        return componentStore;
      },
      deps: [Store, RegisterAskAiStoreProviderService],
    },
  ],
})
export class AskAiOverlayWrapperComponent {
  protected readonly id$ = this.store.select(AskAiSelectors.selectId);

  constructor(private readonly store: Store<RootAiSharedGlobalAskAiState>) {}

  protected closeSidebar(): void {
    this.store.dispatch(AskAiActions.sidebarClosed());
  }
}
