import { MatFormFieldAppearance } from '@angular/material/form-field';
import { FilterType } from '@remberg/global/common/core';
import { Observable } from 'rxjs';
import { compareIDs } from '../helpers/compare-helpers';
import { ApiResponse } from './data-wrappers';
import { EmptyListType } from './empty-list.type';
import { OfflinePopulateType } from './sync.model';

export type SelectAction = {
  icon?: string;
  id: string;
  label: string;
  actionFunction: () => Observable<boolean>;
};

export enum SingleSelectPreviewType {
  IMAGE = 'image',
  ICON = 'icon',
  SVG_ICON = 'svgIcon',
}
export interface SingleSelectOption<T> {
  value: T;
  previewType?: SingleSelectPreviewType;
  previewContent?: string;
  displayValue: string;
  displayValueMultiLineWithIcon?: Record<string, string>;
}

export interface SingleSelectPreviewData {
  type?: SingleSelectPreviewType;
  content: string;
}

export class SingleSelectConfig {
  /** If set to true, then the value of "none" option will be {_id: "none"} instead of null.
   * This is necessary for filtering.
   */
  isUsedForFiltering?: boolean = false;
  /** If set to true, the select will contain a 'Any' option with value 'any'. (default: false)*/
  allowAnySelection?: boolean = false;
  /** If set to true, the select will contain a 'None' option with value null. (default: false)*/
  allowNoneSelection?: boolean = false;
  /** If set to true, the select dropdown will contain a create new option (default: false) */
  showCreateOption: boolean = false;
  /** If set to true (default) the search field will be focused on opening the dropdown */
  initialSearchFocus: boolean = true;
  /** Populate flag for selected elements (must match instanceFetchFunction)*/
  populateSelectedElements?: OfflinePopulateType;
  /** List of actions that the dropdown allows*/
  actions: SelectAction[] = [];
  /** If set to true, a link to the selected option's detail view is added below the dropdown (default: false) */
  showGoToLink: boolean = false;
  /** Base Url for the goToLink (resulting link will be: goToLinkBase + selectedItem._id)*/
  goToLinkBase?: string;

  /** If set to true, the select field header will be hidden in non-edit mode (default: false) */
  hideHeader?: boolean = false;
  /** Label for the create option. */
  createOptionLabel: string = $localize`:@@plusNewEntry:+ New Entry`;
  /** Label for the create button. */
  createButtonLabel: string = $localize`:@@createNew:Create New`;
  /** MatFormFieldAppearance for the select form field */
  appearance: MatFormFieldAppearance = 'outline';
  /** Custom Placeholder string */
  fieldLabel?: string;
  /** Custom SearchPlaceholder string */
  searchPlaceholderLabel: string = $localize`:@@search:Search`;
  /** Custom hint that can be shown below the dropdown */
  hintText: string = '';
  /** Hint text for a missing selection if the field is marked as required */
  selectionRequiredHint: string = $localize`:@@valueRequired:Value required`;
  /** Hint text for a missing selection if the field is marked as required */
  unknownObjectText: string = $localize`:@@unknownValue:Unknown Value`;
  /** Tooltip text for a if the select is disabled */
  selectionDisabledTooltip?: string;
  // Full width on xSmall
  fullWidth: boolean = false;
  /** Label to show if no entries were found */
  noEntriesFoundLabel = $localize`:@@noEntriesFound:No Entries Found`;
  /** Path to the empty list image (for modal) */
  emptyListType?: EmptyListType;
  /** Force flex design into one row without wrap */
  showAsOneRow: boolean = false;

  dataTestId: string = '';
  dataProductTourId: string = '';

  isMultiLine?: boolean;
  isBackArrowVisible?: boolean;
}

export class SingleSelectFunctions<T> {
  /** Function to compare two select values */
  compareFunction: (value1: T | undefined | null, value2: T | undefined | null) => boolean =
    compareIDs;

  /** Function render a select value as a string */
  getValueStringFunction: (value: T) => string = String;

  /** Function render a select value as a string */
  getPreviewDataFunction?: (value: T) => Observable<SingleSelectPreviewData>;

  /** Function render a select value as a string */
  getFilterStringFunction: (value: T) => string = String;

  /** Function to fetch instances by Id */
  instanceFetchFunction?:
    | ((value: string, populate?: boolean) => Observable<T>)
    | ((value: string, populate?: OfflinePopulateType) => Observable<T | undefined>);

  /** Function to open a create dialog */
  openCreateDialogFunction?: () => Observable<T | undefined>;

  /** Function to open a create dialog */
  fetchListValuesWithCountFunction?: (
    search: string,
    filters: FilterType<string>[],
    pageSize: number,
    pageOffset: number,
  ) => Observable<ApiResponse<T[]>>;

  getMultilineTextWithIconFunction?: (value: T) => Record<string, string>;
}
