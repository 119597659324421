import { Assert, FeatureFlagEnum, TwoWayCompareStringType } from '@remberg/global/common/core';
import { TicketPermissions } from '@remberg/users/common/main';

export enum TicketsPermissionEnum {
  TICKETS_ENABLED = 'tickets_enabled',
  TICKETS_CREATE = 'tickets_create',
  TICKETS_UPDATE = 'tickets_update',
  TICKETS_DELETE = 'tickets_delete',
  TICKETS_ADD_ANSWER = 'tickets_addAnswer', // Includes notes
  TICKETS_EXPORT = 'tickets_export',
  TICKETS_MOVE = 'tickets_move',
  TICKETS_ENABLE_AI = 'tickets_enableAi',
  // notes
  TICKETS_DELETE_NOTES_OWN = 'tickets_deleteNotesOwn',
  TICKETS_DELETE_NOTES_ALL = 'tickets_deleteNotesAll',
}

export type AssertMatchingInterfaces = Assert<
  TwoWayCompareStringType<
    `${FeatureFlagEnum.TICKETS_TEMPORARY}_${keyof TicketPermissions}`,
    TicketsPermissionEnum
  >
>;
