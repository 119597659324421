import { AiChatContext, AiChatContextTypeEnum } from '@remberg/ai/common/main';
import { AssetBasic, ProductType } from '@remberg/assets/common/base';
import { Product } from '@remberg/assets/common/main';
import { DriveFile } from '@remberg/files/common/main';
import { createMapper } from '@remberg/global/common/core';

export interface BaseAiChatInputSource extends AiChatContext {
  name: string;
  icon: string;
  isSvgIcon?: boolean;
}

export interface AiChatXInputSource extends BaseAiChatInputSource {
  assetTypeName: string;
  assetTypeId: string;
  assetType: ProductType | string;
  name: string;
  type: AiChatContextTypeEnum.ASSET;
}

export interface AiChatFileInputSource extends BaseAiChatInputSource {
  type: AiChatContextTypeEnum.FILE;
}

export interface AiChatAssetTypeInputSource extends BaseAiChatInputSource {
  tenantId: string;
  type: AiChatContextTypeEnum.ASSET_TYPE;
}

export type AiChatInputSource =
  | AiChatXInputSource
  | AiChatFileInputSource
  | AiChatAssetTypeInputSource;

export function isInputSourceOfType<T extends AiChatInputSource>(
  source: AiChatInputSource,
  type: AiChatContextTypeEnum,
): source is T {
  return (source as T).type === type;
}

export function isAiChatFileInputSource(
  source: AiChatInputSource,
): source is AiChatFileInputSource {
  return isInputSourceOfType<AiChatFileInputSource>(source, AiChatContextTypeEnum.FILE);
}

export function isAiChatXInputSource(source: AiChatInputSource): source is AiChatXInputSource {
  return isInputSourceOfType<AiChatXInputSource>(source, AiChatContextTypeEnum.ASSET);
}

export function isAiChatAssetTypeInputSource(
  source: AiChatInputSource,
): source is AiChatAssetTypeInputSource {
  return isInputSourceOfType<AiChatAssetTypeInputSource>(source, AiChatContextTypeEnum.ASSET_TYPE);
}

export const mapProductToAiChatXInputSource = createMapper(
  (product: Product): AiChatXInputSource => ({
    instanceId: product._id,
    type: AiChatContextTypeEnum.ASSET,
    assetTypeName: product.productTypeName,
    assetTypeId:
      typeof product.productType === 'string' ? product.productType : product.productType._id,
    assetType: product.productType,
    name: product.serialNumber,
    icon: 'asset',
    isSvgIcon: true,
  }),
);

export const mapAssetBasicToAiChatXInputSource = createMapper(
  (assetBasic: AssetBasic): AiChatXInputSource => ({
    instanceId: assetBasic._id,
    type: AiChatContextTypeEnum.ASSET,
    assetTypeName: assetBasic.assetTypeName,
    assetTypeId: assetBasic.assetTypeId,
    assetType: assetBasic.assetTypeId,
    name: assetBasic.serialNumber,
    icon: 'asset',
    isSvgIcon: true,
  }),
);

export const mapAiChatXInputSourceToProduct = createMapper(
  (source: AiChatXInputSource): Product => ({
    _id: source.instanceId,
    serialNumber: source.name,
    productType: source.assetType,
    productTypeName: source.assetTypeName,
  }),
);

export const mapDriveFileToAiChatFileInputSource = createMapper(
  ({ _id, name, isFolder }: DriveFile): AiChatFileInputSource => ({
    instanceId: _id,
    type: AiChatContextTypeEnum.FILE,
    name,
    icon: isFolder ? 'folder' : 'file_open',
  }),
);

export const mapAiChatAssetTypeInputSourceToProductType = createMapper(
  ({ instanceId, name, tenantId }: AiChatAssetTypeInputSource): ProductType => ({
    _id: instanceId,
    label: name,
    tenantId,
  }),
);
export const mapProductTypeToAiChatAssetTypeInputSource = createMapper(
  ({ _id, label, tenantId }: ProductType): AiChatAssetTypeInputSource => ({
    instanceId: _id,
    type: AiChatContextTypeEnum.ASSET_TYPE,
    name: label,
    icon: 'category',
    tenantId,
  }),
);

export const mapAiChatInputSourceToAiChatContext = createMapper(
  ({ instanceId, type }: AiChatInputSource): AiChatContext[] => [
    {
      instanceId,
      type,
    },
  ],
);
