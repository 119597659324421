<div>
  <div class="dialog-header-container">
    <div class="dialog-title-container">
      <mat-icon class="arrow-back" [mat-dialog-close]="">arrow_back</mat-icon>
      <h2 i18n="@@addFilter">Add filter</h2>
    </div>
  </div>

  <div class="content-container">
    <div
      *ngFor="let option of options"
      class="select-option mat-elevation-z4 card is-clickable"
      [ngClass]="{ 'select-option-active': selectedOption?.label }"
      (click)="select(option.identifier)"
      attr.data-test-id="{{ option.identifier }}"
    >
      <div class="option-wrapper table-column-text-ellipsis">
        <mat-icon *ngIf="option.icon">{{ option.icon }}</mat-icon>
        <mat-icon *ngIf="option.svgIcon" [svgIcon]="option.svgIcon"></mat-icon>
        <span class="option-label">{{ option.label }}</span>
      </div>

      <mat-icon *ngIf="selectedOption?.identifier === option.identifier" class="option-icon">
        check
      </mat-icon>
    </div>
  </div>
</div>
