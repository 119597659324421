import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SortDirectionEnum, SortTypeConfig } from '@remberg/global/common/core';
import {
  MANUAL_SORT_DIRECTION,
  MANUAL_SORT_LABEL,
  SORT_DIRECTION_OPTIONS,
  SortField,
  SortingDialogData,
} from '@remberg/global/ui';
import { BehaviorSubject } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../../../store';

@Component({
  selector: 'app-sorting-mobile-dialog',
  templateUrl: 'sorting-mobile-dialog.component.html',
  styleUrls: ['./sorting-mobile-dialog.component.scss'],
})
export class SortingMobileDialogComponent {
  protected currentSortField?: string;
  protected currentSortDirection?: SortDirectionEnum | typeof MANUAL_SORT_DIRECTION;

  protected sortFields: SortField<string>;
  protected defaultSort?: Required<SortTypeConfig<string>>;
  protected isManual?: boolean;

  protected sortDirectionOptions = SORT_DIRECTION_OPTIONS;
  protected readonly MANUAL_SORT_DIRECTION = MANUAL_SORT_DIRECTION;
  protected readonly MANUAL_SORT_LABEL = MANUAL_SORT_LABEL;

  protected doneButtonDisabled$ = new BehaviorSubject<boolean>(false);
  protected readonly deviceType$ = this.store.select(GlobalSelectors.selectDeviceType);

  constructor(
    private store: Store<RootGlobalState>,
    public dialogRef: MatDialogRef<SortingMobileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: SortingDialogData<string>,
  ) {
    this.isManual = config.isManual;
    this.currentSortField = config.isManual ? undefined : config.currentSortField;
    this.currentSortDirection = config.isManual
      ? MANUAL_SORT_DIRECTION
      : config.currentSortDirection;
    this.sortFields = config.sortFields;
    this.defaultSort = config.defaultSort;
  }

  protected reset(): void {
    this.currentSortField = this.defaultSort?.field;
    this.currentSortDirection = this.defaultSort?.direction;
    this.isManual = false;
    this.computeApplyButtonStatus();
  }

  protected onSortFieldChanged(key?: string): void {
    this.currentSortField = key;

    if (
      this.currentSortField &&
      (!this.currentSortDirection || this.currentSortDirection === MANUAL_SORT_DIRECTION)
    ) {
      this.currentSortDirection = SortDirectionEnum.ASC;
    }
    this.isManual = false;
    this.computeApplyButtonStatus();
  }

  protected onSortDirectionChanged(direction?: SortDirectionEnum): void {
    this.currentSortDirection = direction;

    if (!this.currentSortField) {
      this.currentSortField = this.defaultSort?.field;
    }
    this.isManual = false;
    this.computeApplyButtonStatus();
  }

  private computeApplyButtonStatus(): void {
    const disabled: boolean =
      (!this.currentSortDirection && !!this.currentSortField) ||
      (!!this.currentSortDirection && !this.currentSortField);
    this.doneButtonDisabled$.next(disabled);
  }

  protected handleDialogCancel(): void {
    this.dialogRef.close({ isConfirmed: false });
  }

  protected handleDialogApply(): void {
    if (this.currentSortDirection !== MANUAL_SORT_DIRECTION) {
      const sorting: SortTypeConfig<string> = {
        field: this.currentSortField,
        direction: this.currentSortDirection,
      };

      this.dialogRef.close({ isConfirmed: true, sorting });
    } else {
      this.dialogRef.close({ isConfirmed: false });
    }
  }
}
