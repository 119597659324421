import {
  contactCompatibilityToContactBasic,
  organizationCompatibilityToOrganizationBasic,
} from '@remberg/crm/common/main';
import { createMapper } from '@remberg/global/common/core';
import { Product } from '../definitions';
import { ProductCompatibility } from '../definitions/product-compatibility';
export const mapOneProductCompatibilityToProduct = (product: ProductCompatibility): Product => ({
  ...product,
  customerOrganization:
    product.customerOrganization === undefined || typeof product.customerOrganization === 'string'
      ? product.customerOrganization
      : organizationCompatibilityToOrganizationBasic(product.customerOrganization),
  manufacturerOrganization:
    product.manufacturerOrganization === undefined ||
    typeof product.manufacturerOrganization === 'string'
      ? product.manufacturerOrganization
      : organizationCompatibilityToOrganizationBasic(product.manufacturerOrganization),
  customerContact:
    product.customerContact === undefined || typeof product.customerContact === 'string'
      ? product.customerContact
      : contactCompatibilityToContactBasic(product.customerContact),
  manufacturerContact:
    product.manufacturerContact === undefined || typeof product.manufacturerContact === 'string'
      ? product.manufacturerContact
      : contactCompatibilityToContactBasic(product.manufacturerContact),
  parentAsset: product.parentAsset as Product,
  createContext: product.createContext
    ? {
        ...product.createContext,
        source:
          product.createContext.source === undefined ||
          typeof product.createContext.source === 'string'
            ? product.createContext.source
            : contactCompatibilityToContactBasic(product.createContext.source),
      }
    : undefined,
});

export const mapProductCompatibilityToProduct = createMapper(mapOneProductCompatibilityToProduct);
