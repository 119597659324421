import { Pipe, PipeTransform } from '@angular/core';
import { getCacheBreaker } from '../helpers';

@Pipe({ name: 'withCacheBreaker' })
export class WithCacheBreakerPipe implements PipeTransform {
  public transform(value: string): string {
    if (!value) return value;
    return value + getCacheBreaker();
  }
}
