import { createFeatureSelector } from '@ngrx/store';
import { RootGlobalState } from '@remberg/ui-core/core';
import { AskAiState } from './ask-ai/ask-ai.definitions';

export const GLOBAL_ASK_AI_SHARED_STORE_NAME = 'global-ask-ai-shared';

export interface RootGlobalAskAiState {
  askAi: AskAiState;
}

export interface RootAiSharedGlobalAskAiState extends RootGlobalState {
  [GLOBAL_ASK_AI_SHARED_STORE_NAME]: RootGlobalAskAiState;
}

export const selectGlobalAskAiSharedState = createFeatureSelector<RootGlobalAskAiState>(
  GLOBAL_ASK_AI_SHARED_STORE_NAME,
);
