import { CustomPropertyTypesEnum } from './enums';

export const CUSTOM_PROPERTY_TYPE_TO_ICON: {
  [key in CustomPropertyTypesEnum]: { icon?: string; svgIcon?: string };
} = {
  [CustomPropertyTypesEnum.INPUT_TEXT]: { icon: 'abc' },
  [CustomPropertyTypesEnum.TEXTAREA]: { icon: 'abc' },
  [CustomPropertyTypesEnum.URL]: { icon: 'http' },
  [CustomPropertyTypesEnum.INPUT_NUMBER]: { icon: '123' },
  [CustomPropertyTypesEnum.CHECKBOX]: { icon: 'check_box' },
  [CustomPropertyTypesEnum.SELECT]: { icon: 'checklist' },
  [CustomPropertyTypesEnum.REMBERG_ASSET_SELECT]: { svgIcon: 'asset' },
  [CustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT]: { icon: 'domain' },
  [CustomPropertyTypesEnum.REMBERG_CONTACT_SELECT]: { icon: 'person' },
  [CustomPropertyTypesEnum.REMBERG_DATE]: { icon: 'calendar_today' },
  [CustomPropertyTypesEnum.REMBERG_DATETIME]: { icon: 'calendar_today' },
};
