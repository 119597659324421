import { createMapper } from '@remberg/global/common/core';
import { AssetPublic } from '../../assets';
import { Product } from '../definitions';

function mapOneAssetPublicToProduct(assetPublic: AssetPublic): Product {
  return {
    _id: assetPublic._id,
    tenantId: assetPublic.tenantId,
    productType: {
      _id: assetPublic.assetTypeId,
      tenantId: assetPublic.tenantId,
      label: assetPublic.assetTypeLabel,
      manufacturer: assetPublic.assetTypeManufacturerName,
      image: assetPublic.assetTypeImageId,
    },
    productTypeName: assetPublic.assetTypeLabel,
    serialNumber: assetPublic.serialNumber,
  };
}

export const mapAssetPublicToAsset = createMapper(mapOneAssetPublicToProduct);
