import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Encoding, Filesystem, ReadFileOptions, ReadFileResult } from '@capacitor/filesystem';
import {
  API_URL_PLACEHOLDER,
  AbortToken,
  LogService,
  MOBILE_LOG_DIRECTORY,
  PushStatus,
} from '@remberg/global/ui';
import * as Sentry from '@sentry/angular';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MobileLogsService } from './mobile-logs.service';

@Injectable({
  providedIn: 'root',
})
export class MobileLogsUploadService {
  public readonly syncLogsUrl = `${API_URL_PLACEHOLDER}/sync/logs`;

  constructor(
    private http: HttpClient,
    private logger: LogService,
    private mobileLogsService: MobileLogsService,
  ) {}

  public async pushLogsToServer(
    statusSubject?: BehaviorSubject<PushStatus | undefined>,
    abortToken?: AbortToken,
  ): Promise<boolean> {
    const status = statusSubject?.getValue();

    try {
      this.logger.debug()('Pushing mobileLogs to server...');

      if (status) {
        status.mobileLogs = 0;
        statusSubject?.next(status);
      }

      await this.mobileLogsService.zipLogFiles();
      const logFiles = await this.mobileLogsService.getLogZipFiles();

      this.logger.debug()('Logfiles found: ', logFiles);

      // upload logfiles one by one
      for (const logFile of logFiles) {
        // before every upload, we can abort:
        abortToken?.check();

        const options: ReadFileOptions = {
          path: 'logs/' + logFile,
          directory: MOBILE_LOG_DIRECTORY,
          encoding: Encoding.UTF8,
        };

        this.logger.debug()('Processing file for upload: ', logFile);

        const content: ReadFileResult = await Filesystem.readFile(options);
        let blob: Blob;

        if (content.data instanceof Blob) {
          blob = content.data;
        } else {
          const res = await fetch(content.data);
          blob = await res.blob();
        }

        const theFile = new File([blob], logFile, { type: 'application/zip' });

        const formData = new FormData();
        formData.append('uploadFile', theFile, logFile);

        await this.http
          .post<any>(this.syncLogsUrl, formData)
          .pipe(map((res) => res.data))
          .toPromise();

        await this.mobileLogsService.deleteFile(logFile);

        if (status && status.mobileLogs !== undefined) {
          status.mobileLogs = status.mobileLogs + 1 / logFiles.length;
          statusSubject?.next(status);
        }
      }

      if (status) {
        status.mobileLogs = 1;
        statusSubject?.next(status);
      }

      await this.mobileLogsService.deleteOldLogFiles();

      return true;
    } catch (error) {
      this.logger.debug()('Error pushing mobileLogs: ', error);
      Sentry.captureException(error);
      if (status) {
        status.mobileLogs = 0;
        statusSubject?.next(status);
      }
      return false;
    }
  }
}
