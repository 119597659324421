import { ContactsOrganizationTypeFilterEnum } from '@remberg/crm/common/main';

export type FilterField<T extends string> = { [key in T]?: FilterFieldOption<T> };
export interface FilterFieldOption<T> {
  identifier: T;
  label: string;
  type: FilterTypeEnum;
  icon?: string;
  svgIcon?: string;
  /** Static options used for Enum Type or Static Select Custom Prop */
  options?: FilterValueOption[];
  /** Filter should only be available if user belongs to an OEM*/
  oemOnly?: boolean;
  /** Filter should only be available if feature flag is enabled*/
  featureFlagOnly?: boolean;
  /** Filter should only be available if user is in online mode*/
  onlineOnly?: boolean;
  /** Filter should includo Any and None Option*/
  anyAndNoneOptions?: boolean;
  /** Only for user select*/
  organizationType?: ContactsOrganizationTypeFilterEnum;
}

export interface FilterValueOption {
  identifier: string;
  label: string;
}

export enum FilterTypeEnum {
  FORM_TEMPLATE = 'formTemplate',
  USER = 'user',
  ASSET = 'asset',
  ASSET_TYPE = 'assetType',
  WORK_ORDER = 'workOrder',
  ACCOUNT = 'account',
  ORGANIZATION = 'organization',
  TENANT = 'tenant',
  CONTACT = 'contact',
  ENUM = 'enum',
  BOOLEAN = 'boolean',
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  MAINTENANCE_PLAN = 'maintenancePlan',
  CASE = 'case',
  WORK_ORDER_TYPE = 'workOrderType',
  WORK_ORDER_STATUS = 'workOrderStatus',

  USER_GROUP = 'userGroup',
  STATIC_SELECT = 'staticSelect',
  SUPPORT_EMAIL = 'supportEmail',
  COUNTRY = 'country',
  CITY = 'city',
  PROVINCE = 'countryProvince',
  CASE_CATEGORY = 'caseCategory',
}
