import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  UnknownOr,
  formatToRembergNumberString,
  isUnknownObject,
} from '@remberg/global/common/core';
import {
  ApiResponse,
  EmptyListType,
  MultiSelectFunctions,
  MultiSelectModalOptionLabel,
} from '@remberg/global/ui';
import { WorkOrder } from '@remberg/work-orders-legacy/common/main';
import { WorkOrderService } from '@remberg/work-orders-legacy/ui/clients';
import { Observable, of } from 'rxjs';
import {
  BaseMultiselectComponent,
  BaseMultiselectComponentTranslations,
} from '../base-multiselect/base-multiselect.component';

@Component({
  selector: 'app-work-order-multiselect',
  templateUrl: '../base-multiselect/base-multiselect.component.html',
  styleUrls: ['../base-multiselect/base-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkOrderMultiselectComponent extends BaseMultiselectComponent<UnknownOr<WorkOrder>> {
  @Input() public titleIcon: string = 'handyman-work';
  @Input() public titleIconSvg = '';
  @Input() public translations: BaseMultiselectComponentTranslations = {
    title: $localize`:@@WorkOrders:Work Orders`,
  };

  protected readonly emptyListType: EmptyListType = 'workorders';

  private getModalOptionLabel = (workOrder: UnknownOr<WorkOrder>): MultiSelectModalOptionLabel => ({
    label: isUnknownObject(workOrder) ? '' : (workOrder.title ?? ''),
    subLabel: isUnknownObject(workOrder) ? '' : formatToRembergNumberString(workOrder.workOrderID),
  });

  private getOptionLabel = (workOrder: UnknownOr<WorkOrder>): string => {
    const optionLabel = this.getModalOptionLabel(workOrder);
    return `${optionLabel.label} (${optionLabel.subLabel})`;
  };

  private getChipLabel = (workOrder: UnknownOr<WorkOrder>): string =>
    this.getOptionLabel(workOrder);

  private getChipLink = (workOrder: UnknownOr<WorkOrder>): Observable<string> =>
    of(isUnknownObject(workOrder) ? '' : `/workorders/detail/${workOrder._id}`);

  private fetchItemsWithCount = (
    search: string,
    pageSize: number,
    pageOffset: number,
  ): Observable<ApiResponse<WorkOrder[]>> =>
    this.workOrderService.getWorkOrdersWithCount(
      false,
      pageSize,
      pageOffset,
      'asc',
      'workOrderID',
      search,
    );

  protected readonly functions: MultiSelectFunctions<UnknownOr<WorkOrder>> = {
    getChipLabel: this.getChipLabel,
    getChipLink: this.getChipLink,
    getOptionLabel: this.getChipLabel,
    getModalOptionLabel: this.getModalOptionLabel,
    fetchListValuesWithCountFunction: this.fetchItemsWithCount,
  };

  constructor(private readonly workOrderService: WorkOrderService) {
    super();
  }
}
