import { FormArray, FormControl, FormGroup } from '@angular/forms';

export type FormState<T> = {
  value?: Partial<ExtractFormControlType<T>>;
  isDirty?: boolean;
  isValid?: boolean;
  // Default: 'user'
  changedBy?: FormStateChangedByTypeEnum;
};

export enum FormStateChangedByTypeEnum {
  USER = 'user',
  SYSTEM = 'system',
}

export type ExtractFormControlType<T> = {
  [K in keyof T]: T[K] extends FormControl<infer U>
    ? U
    : T[K] extends FormGroup<infer U>
      ? ExtractFormControlType<U>
      : T[K] extends FormArray<infer U>
        ? ExtractFormControlType<U>[]
        : T[K];
};
