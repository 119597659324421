import { Observable, OperatorFunction, filter, pairwise } from 'rxjs';

/**
 * Filters the source observable until the specified dialog is closed.
 *
 * This operator listens to a stream of values and waits until the dialog identified by `dialogId`
 * is closed. The dialog is considered closed when its ID is present in the list of previously
 * open dialogs but absent in the current list of open dialogs.
 *
 * @template T - The type of the source observable's emitted values.
 * @template E - A string literal type representing the dialog ID.
 *
 * @param {E} dialogId - The ID of the dialog to monitor for closure.
 * @param {function} [extractDialogIds=(...args: any) => [string[], string[]]] -
 * A function that extracts two lists of dialog IDs from the emitted values. The first list represents
 * the previous state of open dialogs, and the second list represents the current state of open dialogs.
 * Defaults to extracting from the second and third arguments of the emitted values.
 *
 * @returns {OperatorFunction<T, [T, T]>} - An operator that emits when the dialog with the specified
 * `dialogId` is closed, passing through the pair of values (previous and current) from the source observable.
 */
export function filterUntilDialogClosed<T, E extends string>(
  dialogId: E,
  extractDialogIds: (...args: any[]) => [string[], string[]] = (previous, current) => [
    previous,
    current,
  ],
): OperatorFunction<T, [T, T]> {
  return (source$: Observable<T>) =>
    source$.pipe(
      pairwise(),
      filter(([previous, current]) => {
        const [previousOpenDialogs, currentOpenDialogs] = extractDialogIds(previous, current);
        return previousOpenDialogs.includes(dialogId) && !currentOpenDialogs.includes(dialogId);
      }),
    );
}
