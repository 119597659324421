import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-toggle-with-texts',
  templateUrl: './toggle-with-texts.component.html',
  styleUrls: ['./toggle-with-texts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleWithTextsComponent implements ControlValueAccessor, OnDestroy {
  @ViewChild('toggleRef', { static: false }) protected toggleRef!: MatSlideToggle;
  protected readonly formControl = new FormControl<boolean | undefined>(undefined, {
    nonNullable: true,
  });

  @Input({ required: true }) public label!: string;
  @Input() public description?: string;
  @Input() public togglePosition: 'left' | 'right' = 'left';
  @Input() public materialColor: 'primary' | 'accent' | 'warn' = 'primary';

  @Output() public toggleChanged = new EventEmitter<boolean>();

  private readonly touched$ = new Subject<void>();
  private readonly subscription = new Subscription();

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    @Optional() @Self() private readonly ngControl?: NgControl,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public writeValue(value: boolean | undefined): void {
    this.formControl.reset(value, { emitEvent: false });
  }

  public registerOnChange(onChangeFn: (value: boolean | undefined) => void): void {
    this.subscription.add(this.formControl.valueChanges.subscribe(onChangeFn));
  }

  public registerOnTouched(onTouchedFn: () => void): void {
    this.subscription.add(this.touched$.subscribe(onTouchedFn));
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }
    this.cdRef.markForCheck();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected onToggleChanged(currentValue: boolean): void {
    this.toggleChanged.emit(currentValue);
  }

  protected onTextClick(): void {
    this.formControl.setValue(!this.formControl.value);
    this.onToggleChanged(Boolean(this.formControl.value));
  }
}
