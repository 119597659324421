export const REMBERG_AI_CHAT_SOURCE_ARRAY_TOKEN = 'REMBERG_AI_SOURCE_ARRAY_TOKEN';
export const REMBERG_AI_CHAT_ERROR_TOKEN = 'REMBERG_AI_ERROR_TOKEN';
export const REMBERG_AI_CHAT_CONVERSATION_ID_TOKEN = 'REMBERG_AI_CHAT_CONVERSATION_ID_TOKEN';

export interface AiChatSource {
  content: string;
  rembergFileId: string;
  fileName: string;
  parentFolderId?: string;
  location: {
    page: number;
  };
  metaData: Record<string, unknown>;
}
