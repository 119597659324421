import { Injectable } from '@angular/core';

const MAX_HISTORY_LENGTH = 5;

@Injectable({
  providedIn: 'root',
})
// TODO rework the routerHistoryService to keep track of the history taking care of the direction of the navigation
// https://gitlab.com/remberg/remberg/-/issues/5322
export class RouterHistoryService {
  /** Array that contains the url history. The first element is the newest, the last element is the oldest.
   * For the moment, we store a maximum of three URLs in this array. */
  private urlHistory: string[] = [];

  constructor() {
    this.urlHistory = [];
  }
  public getBeforePreviousUrl(): string | undefined {
    if (this.urlHistory.length >= 3) {
      return this.urlHistory[2];
    } else {
      return undefined;
    }
  }
  public getPreviousUrl(): string | undefined {
    if (this.urlHistory.length === 1) {
      return undefined;
    } else {
      return this.urlHistory[1];
    }
  }

  public getCurrentUrl(): string {
    return this.urlHistory[0];
  }
  public addUrl(url: string): void {
    if (this.urlHistory[0] !== url) {
      this.urlHistory.unshift(url);
    }
    if (this.urlHistory.length > MAX_HISTORY_LENGTH) {
      this.urlHistory.pop();
    }
  }

  public removeLatestUrl(url: string): void {
    while (this.urlHistory[0] === url) {
      this.urlHistory.shift();
    }
  }

  public removeCurrentUrl(): void {
    this.urlHistory.shift();
  }

  public get history(): string[] {
    return [...this.urlHistory];
  }
}
