import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AdvancedFilterConcatOperatorEnum,
  AdvancedFilterOperatorEnum,
} from '@remberg/advanced-filters/common/main';
import { isCustomPropertyKey } from '@remberg/custom-properties/common/main';
import {
  CreationTypeEnum,
  INSTANCE_COUNTER_REGEX,
  getNumberCount,
} from '@remberg/global/common/core';
import {
  LogService,
  SQLSortDirection,
  UnreachableCaseError,
  assertDefined,
  stringToSQLSortDirection,
} from '@remberg/global/ui';
import { WorkOrderBasic } from '@remberg/work-orders/common/base';
import {
  WORK_ORDER_FIND_ONE_POPULATED_FIELDS,
  WorkOrder,
  WorkOrderAdvancedFilter,
  WorkOrderAdvancedFilterObject,
  WorkOrderFilterFieldEnum,
  WorkOrderFindManyBasicByIdsBody,
  WorkOrderFindManyQuery,
  WorkOrderFindManyResponse,
  WorkOrderRaw,
  mapToWorkOrderBasic,
} from '@remberg/work-orders/common/main';
import { WorkOrder2OfflineServiceInterface } from '@remberg/work-orders/ui/clients';
import { Observable, firstValueFrom, from, map } from 'rxjs';
import {
  SQLConcatOperator,
  concatSQLFiltersByOperator,
  generateArrayContainsSQLFilter,
  generateArrayDoesNotContainSQLFilter,
  generateBooleanSQLFilterItemValue,
  generateContainsSQLFilter,
  generateDoesNotContainSQLFilter,
  generateEqualsSQLFilter,
  generateIsEmptySQLFilter,
  generateNotEmptySQLFilter,
  generateNotEqualsSQLFilter,
  sqlFiltersHelper,
} from '../../../helpers/sqlFiltersHelper';
import { GlobalSelectors, RootGlobalState } from '../../../store';
import { BaseOfflineService } from '../../base.offline.service';
import { SqlDBService } from '../../sqlDB.service';
import {
  SORT_FIELD_TO_COLUMN_NAME_MAP,
  WORK_ORDER2_SQL_QUERY_PARAMS,
  WORK_ORDER_DATE_COLUMNS,
  WORK_ORDER_FILTER_ENUM_TO_COLUMN_NAME,
  WorkOrderOfflineColumnNamesEnum,
  WorkOrderOfflinePopulated,
} from './work-order-2-offline.definitions';
import {
  mapToWorkOrder,
  mapToWorkOrderListItem,
  mapToWorkOrderOfflinePopulate,
} from './work-order-2-offline.helpers';

@Injectable()
export class WorkOrder2OfflineService
  extends BaseOfflineService<WorkOrderRaw, WorkOrderFilterFieldEnum>
  implements WorkOrder2OfflineServiceInterface
{
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, WORK_ORDER2_SQL_QUERY_PARAMS, logger, store);
  }

  public async findOne(id: string): Promise<WorkOrder> {
    const workOrderOfflinePopulated = (await this.getInstance(
      id,
      mapToWorkOrderOfflinePopulate(WORK_ORDER_FIND_ONE_POPULATED_FIELDS),
    )) as WorkOrderOfflinePopulated;
    return mapToWorkOrder(workOrderOfflinePopulated);
  }

  public async findMany(options?: WorkOrderFindManyQuery): Promise<WorkOrderFindManyResponse> {
    const { limit, page, sortField, sortDirection, search, filterObject, populate } = options ?? {};
    const filterStrings: string[] = [];

    const rembergUser = await firstValueFrom(
      this.store.select(GlobalSelectors.selectCurrentRembergUser),
    );

    const advancedFilterString = this.getAdvancedFiltersString(
      filterObject,
      rembergUser?.userGroupIds,
    );
    if (advancedFilterString) {
      filterStrings.push(advancedFilterString);
    }

    if (search) {
      const matches = search.match(INSTANCE_COUNTER_REGEX);
      if (!matches?.[1] || isNaN(parseInt(matches[1], 10))) {
        filterStrings.push(
          `${WORK_ORDER2_SQL_QUERY_PARAMS.tableName}.${WorkOrderOfflineColumnNamesEnum.SUBJECT} LIKE '%${search}%'`,
        );
      } else {
        filterStrings.push(
          `(${WORK_ORDER2_SQL_QUERY_PARAMS.tableName}.${WorkOrderOfflineColumnNamesEnum.SUBJECT} LIKE '%${search}%' OR ${
            WORK_ORDER2_SQL_QUERY_PARAMS.tableName
          }.${WorkOrderOfflineColumnNamesEnum.COUNTER} = ${parseInt(matches[1], 10)})`,
        );
      }
    }

    // sorting (must be mapped to the right column):
    let sqlSortDirection: SQLSortDirection | undefined = stringToSQLSortDirection(sortDirection);

    let sortColumn = undefined;
    if (sortField) {
      sortColumn = SORT_FIELD_TO_COLUMN_NAME_MAP[sortField];

      if (sortColumn === WorkOrderOfflineColumnNamesEnum.DUE_DATE) {
        if (sqlSortDirection === SQLSortDirection.ASC) {
          sqlSortDirection = SQLSortDirection.ASC_NULLS_LAST;
        } else if (sqlSortDirection === SQLSortDirection.DESC) {
          sqlSortDirection = SQLSortDirection.DESC_NULLS_FIRST;
        }
      }
    }

    const res = await this.getInstancesWithCount(
      limit,
      page,
      sortColumn,
      sqlSortDirection,
      filterStrings.join(' AND '),
      mapToWorkOrderOfflinePopulate(populate),
    );

    return {
      data: (res.data as WorkOrderOfflinePopulated[]).map((workOrder) =>
        mapToWorkOrderListItem(workOrder),
      ),
      count: res.count ? getNumberCount(res.count) : 0,
    };
  }

  public findManyBasicByIds(body: WorkOrderFindManyBasicByIdsBody): Observable<WorkOrderBasic[]> {
    if (!body.workOrderIds?.length) {
      return from([]);
    }
    const filterString = `${WORK_ORDER2_SQL_QUERY_PARAMS.tableName}._id IN (${body.workOrderIds.map((id) => `'${id}'`).join(',')})`;
    return from(this.getInstances(undefined, undefined, undefined, undefined, filterString)).pipe(
      map((workOrderRaws) => workOrderRaws.map(mapToWorkOrderBasic)),
    );
  }

  public override getAdvancedFiltersString(
    filterQuery?: WorkOrderAdvancedFilterObject,
    userGroupIds?: string[],
  ): string | undefined {
    if (!filterQuery?.filters.length) {
      return undefined;
    }

    const sqlFilters = filterQuery.filters.map((filter) =>
      this.getAdvancedFilterString(filter, userGroupIds),
    );

    return concatSQLFiltersByOperator(
      sqlFilters,
      filterQuery.concatOperator === AdvancedFilterConcatOperatorEnum.OR
        ? SQLConcatOperator.OR
        : SQLConcatOperator.AND,
    );
  }

  public override getAdvancedFilterString(
    filter: WorkOrderAdvancedFilter,
    userGroupIds?: string[],
  ): string {
    const columnName = WORK_ORDER_FILTER_ENUM_TO_COLUMN_NAME[filter.identifier];
    const tableName = WORK_ORDER2_SQL_QUERY_PARAMS.tableName;

    if (isCustomPropertyKey(filter.identifier)) {
      this.logger.warn()(
        `Filtering by Custom property ${filter.identifier} is not supported in offline mode.`,
      );
      return '';
    }

    switch (filter.identifier) {
      case WorkOrderFilterFieldEnum.RELATED_ASSET:
      case WorkOrderFilterFieldEnum.RESPONSIBLE_CONTACT:
      case WorkOrderFilterFieldEnum.RELATED_ORGANIZATION:
      case WorkOrderFilterFieldEnum.PRIORITY:
      case WorkOrderFilterFieldEnum.STATUS:
      case WorkOrderFilterFieldEnum.TYPE:
      case WorkOrderFilterFieldEnum.RELATED_CASE:
      case WorkOrderFilterFieldEnum.RESPONSIBLE_GROUP:
      case WorkOrderFilterFieldEnum.EXTERNAL_REFERENCE:
      case WorkOrderFilterFieldEnum.CITY:
      case WorkOrderFilterFieldEnum.COUNTRY:
      case WorkOrderFilterFieldEnum.COUNTRY_PROVINCE:
      case WorkOrderFilterFieldEnum.ZIP_CODE:
      case WorkOrderFilterFieldEnum.STREET:
      case WorkOrderFilterFieldEnum.PARENT_WORK_ORDER:
      case WorkOrderFilterFieldEnum.RELATED_CONTACT:
      case WorkOrderFilterFieldEnum.PLANNING_ASSIGNED_GROUP:
      case WorkOrderFilterFieldEnum.PLANNING_ASSIGNED_CONTACT:
        return sqlFiltersHelper(filter, `${tableName}.${columnName}`);
      case WorkOrderFilterFieldEnum.CREATED_AT:
      case WorkOrderFilterFieldEnum.UPDATED_AT:
      case WorkOrderFilterFieldEnum.PLANNING_END_DATE:
      case WorkOrderFilterFieldEnum.PLANNING_START_DATE:
      case WorkOrderFilterFieldEnum.DUE_DATE: {
        const isRembergDate = WORK_ORDER_DATE_COLUMNS.includes(filter.identifier);
        return sqlFiltersHelper(filter, `${tableName}.${columnName}`, isRembergDate);
      }
      case WorkOrderFilterFieldEnum.STATUS_COMPLETED:
        return generateBooleanSQLFilterItemValue(filter, `${tableName}.${columnName}`);
      case WorkOrderFilterFieldEnum.TOUCHED:
        return generateBooleanSQLFilterItemValue(filter, `${tableName}.${columnName}`);
      case WorkOrderFilterFieldEnum.INVOLVED_CONTACT: {
        return this.getInvolvedUserAdvancedFilter(filter);
      }
      case WorkOrderFilterFieldEnum.MY_GROUPS: {
        return this.getMyGroupsAdvancedFilter(filter, userGroupIds);
      }
      case WorkOrderFilterFieldEnum.CREATED_BY: {
        return this.getCreatedByAdvancedFilter(filter);
      }
      case WorkOrderFilterFieldEnum.UPDATE_BY: {
        return this.getUpdatedByAdvancedFilter(filter);
      }
      case WorkOrderFilterFieldEnum.FAMILY:
      case WorkOrderFilterFieldEnum.ID:
      case WorkOrderFilterFieldEnum.MAINTENANCE_PLAN:
      case WorkOrderFilterFieldEnum.RELATED_ASSETS:
      case WorkOrderFilterFieldEnum.BOUNDING_BOX: {
        return ''; // Not supported in offline mode
      }
      default:
        throw new UnreachableCaseError(filter.identifier);
    }
  }

  private getInvolvedUserAdvancedFilter(filter: WorkOrderAdvancedFilter): string {
    const tableName = WORK_ORDER2_SQL_QUERY_PARAMS.tableName;

    switch (filter.operator) {
      case AdvancedFilterOperatorEnum.IS:
        assertDefined(filter.value, 'filter.value should be defined for filter operator is');
        return concatSQLFiltersByOperator(
          [
            `${generateEqualsSQLFilter(`${tableName}.${WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_CONTACT_ID}`, filter.value)}`,
            `${generateContainsSQLFilter(`${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_ASSIGNED_CONTACT_IDS}`, filter.value)}`,
          ],
          SQLConcatOperator.OR,
        );
      case AdvancedFilterOperatorEnum.IS_NOT:
        assertDefined(filter.value, 'filter.value should be defined for filter operator is not');
        return concatSQLFiltersByOperator(
          [
            concatSQLFiltersByOperator(
              [
                `${generateNotEqualsSQLFilter(`${tableName}.${WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_CONTACT_ID}`, filter.value)}`,
                generateIsEmptySQLFilter(
                  `${tableName}.${WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_CONTACT_ID}`,
                ),
              ],
              SQLConcatOperator.OR,
            ),
            concatSQLFiltersByOperator(
              [
                generateDoesNotContainSQLFilter(
                  `${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_ASSIGNED_CONTACT_IDS}`,
                  filter.value,
                ),
                generateIsEmptySQLFilter(
                  `${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_ASSIGNED_CONTACT_IDS}`,
                ),
              ],
              SQLConcatOperator.OR,
            ),
          ],
          SQLConcatOperator.AND,
        );
      case AdvancedFilterOperatorEnum.IS_EMPTY:
        return concatSQLFiltersByOperator(
          [
            `${generateIsEmptySQLFilter(`${tableName}.${WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_CONTACT_ID}`)}`,
            `${generateIsEmptySQLFilter(`${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_ASSIGNED_CONTACT_IDS}`)}`,
          ],
          SQLConcatOperator.AND,
        );
      case AdvancedFilterOperatorEnum.IS_NOT_EMPTY:
        return concatSQLFiltersByOperator(
          [
            `${generateNotEmptySQLFilter(`${tableName}.${WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_CONTACT_ID}`)}`,
            `${generateNotEmptySQLFilter(`${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_ASSIGNED_CONTACT_IDS}`)}`,
          ],
          SQLConcatOperator.OR,
        );

      default:
        throw new Error(
          `Operator ${filter.operator} can't be used for ${WorkOrderFilterFieldEnum.INVOLVED_CONTACT} filter type`,
        );
    }
  }

  private getMyGroupsAdvancedFilter(
    filter: WorkOrderAdvancedFilter,
    userGroupIds?: string[],
  ): string {
    const tableName = WORK_ORDER2_SQL_QUERY_PARAMS.tableName;

    assertDefined(userGroupIds, 'userGroupIds should be defined for filter type myGroups');

    const userGroupsString = userGroupIds?.join("', '");

    if (filter.value === 'true') {
      return concatSQLFiltersByOperator(
        [
          `${tableName}.${WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_GROUP_ID} IN ('${userGroupsString}')`,
          generateArrayContainsSQLFilter(
            `${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_ASSIGNED_GROUP_IDS}`,
            userGroupIds,
          ),
        ],
        SQLConcatOperator.OR,
      );
    }
    const notResponsibleGroupQuery = concatSQLFiltersByOperator(
      [
        `${tableName}.${WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_GROUP_ID} NOT IN ('${userGroupsString}')`,
        generateIsEmptySQLFilter(
          `${tableName}.${WorkOrderOfflineColumnNamesEnum.RESPONSIBLE_GROUP_ID}`,
        ),
      ],
      SQLConcatOperator.OR,
    );

    const notPlanningAssignedGroupQuery = concatSQLFiltersByOperator(
      [
        generateArrayDoesNotContainSQLFilter(
          `${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_ASSIGNED_GROUP_IDS}`,
          userGroupIds,
        ),
        generateIsEmptySQLFilter(
          `${tableName}.${WorkOrderOfflineColumnNamesEnum.PLANNING_ASSIGNED_GROUP_IDS}`,
        ),
      ],
      SQLConcatOperator.OR,
    );

    return concatSQLFiltersByOperator(
      [notPlanningAssignedGroupQuery, notResponsibleGroupQuery],
      SQLConcatOperator.AND,
    );
  }

  private getCreatedByAdvancedFilter(filter: WorkOrderAdvancedFilter): string {
    const tableName = WORK_ORDER2_SQL_QUERY_PARAMS.tableName;

    let filterString = '';
    switch (filter.operator) {
      case AdvancedFilterOperatorEnum.IS:
        assertDefined(filter.value, 'filter.value should be defined for filter operator is');
        filterString = generateEqualsSQLFilter(
          `${tableName}.${WorkOrderOfflineColumnNamesEnum.CREATED_BY_ID}`,
          filter.value,
        );
        break;
      case AdvancedFilterOperatorEnum.IS_NOT:
        assertDefined(filter.value, 'filter.value should be defined for filter operator is not');
        filterString = generateNotEqualsSQLFilter(
          `${tableName}.${WorkOrderOfflineColumnNamesEnum.CREATED_BY_ID}`,
          filter.value,
        );
        break;
      default:
        throw new Error(
          `Operator ${filter.operator} can't be used for ${WorkOrderFilterFieldEnum.CREATED_BY} filter type`,
        );
    }

    return concatSQLFiltersByOperator(
      [
        filterString,
        generateEqualsSQLFilter(
          `${tableName}.${WorkOrderOfflineColumnNamesEnum.CREATED_BY_TYPE}`,
          CreationTypeEnum.USER,
        ),
      ],
      SQLConcatOperator.AND,
    );
  }

  private getUpdatedByAdvancedFilter(filter: WorkOrderAdvancedFilter): string {
    const tableName = WORK_ORDER2_SQL_QUERY_PARAMS.tableName;

    let filterString = '';
    switch (filter.operator) {
      case AdvancedFilterOperatorEnum.IS:
        assertDefined(filter.value, 'filter.value should be defined for filter operator is');
        filterString = generateEqualsSQLFilter(
          `${tableName}.${WorkOrderOfflineColumnNamesEnum.UPDATE_BY_ID}`,
          filter.value,
        );
        break;
      case AdvancedFilterOperatorEnum.IS_NOT:
        assertDefined(filter.value, 'filter.value should be defined for filter operator is not');
        filterString = generateNotEqualsSQLFilter(
          `${tableName}.${WorkOrderOfflineColumnNamesEnum.UPDATE_BY_ID}`,
          filter.value,
        );
        break;
      default:
        throw new Error(
          `Operator ${filter.operator} can't be used for ${WorkOrderFilterFieldEnum.UPDATE_BY} filter type`,
        );
    }

    return concatSQLFiltersByOperator(
      [
        filterString,
        generateEqualsSQLFilter(
          `${tableName}.${WorkOrderOfflineColumnNamesEnum.UPDATED_BY_TYPE}`,
          CreationTypeEnum.USER,
        ),
      ],
      SQLConcatOperator.AND,
    );
  }
}
