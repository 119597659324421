import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeAll } from 'rxjs/operators';

@Pipe({
  name: 'transformToBase64',
})
export class TransformToBase64Pipe implements PipeTransform {
  constructor(private http: HttpClient) {}

  public transform(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map(
        (val) =>
          new Observable<string>((observer) => {
            const reader = new FileReader();
            reader.onloadend = function (): void {
              const base64data = reader.result;
              observer.next(base64data as string);
            };
            reader.readAsDataURL(val);
          }),
      ),
      mergeAll(),
    );
  }
}
