import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getPlatforms, isPlatform } from '@ionic/angular';
import { PipelineMeta, VERSION } from '@remberg/global/common/version';
import { environment } from '@remberg/global/ui';
import * as SentryAngular from '@sentry/angular';
import { getParser } from 'bowser';
import { AppModule } from './app/app.module';

// Debug Information

// check browser type and version
const browser = getParser(window.navigator.userAgent);
const isValidBrowser = browser.satisfies({
  iOS: {
    firefox: '>=10', // for iOS
    safari: '>=10',
  },
  Electron: '>=10',
  chrome: '>=45',
  opera: '>=45',
  firefox: '>=53',
  edge: '>=13',
  safari: '>=10',
  'internet explorer': '>10',
  blackberry: '>1',
  android: '>=3',
  amazon_silk: '>=3',
  samsung_internet: '>=4',
});

// eslint-disable-next-line no-console
console.log('Platform Info:');
// eslint-disable-next-line no-console
console.log(getPlatforms());
// eslint-disable-next-line no-console
console.log('Browser Info:');
// eslint-disable-next-line no-console
console.log(browser);

declare global {
  // eslint-disable-next-line no-var
  var __env: PipelineMeta;
}
// eslint-disable-next-line no-console
console.log('__env', window.__env);

// Setting up Sentry Tracking
const getEnvironment = () => {
  if (isPlatform('electron') || isPlatform('capacitor')) {
    return environment.production ? 'production-mobile' : 'test-mobile';
  }

  const splittedHostname = window.location.hostname.split('.');
  if (splittedHostname.length > 3) {
    splittedHostname.shift();
  }
  return environment.live ? splittedHostname.join('.') : 'dev';
};

const env = getEnvironment();

SentryAngular.init({
  // Should be a live build and not on a test domain to activate this.
  // Needed so that CI runners do not show up in Sentry.
  dsn: undefined, // Deactivated due to overload, we need to clean up
  // transaction and error handling before activating again on production
  /* environment.live &&
    !window.location.hostname.includes('remberg.test') &&
    !window.location.hostname.includes('qm.dev.remberg.') &&
    !window.location.hostname.includes('qm.staging.remberg.') &&
    !window.location.hostname.startsWith('preview-') ?
    'https://83565320709a4794b27da0320bdc6606@o508783.ingest.sentry.io/5601810' :
    '', */
  transport: SentryAngular.makeBrowserOfflineTransport(SentryAngular.makeFetchTransport),
  release: `${VERSION}-${window.__env.commitId}`,
  environment: env,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeSend(
    event: SentryAngular.ErrorEvent,
    _hint?: SentryAngular.EventHint,
  ): SentryAngular.ErrorEvent | null {
    if (event.request?.url?.includes('http://remberg.test/')) {
      // This is the case on the CI runner so we exclude this.
      return null;
    }
    return event;
  },
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // replaysSessionSampleRate: environment.production ? 0.0001 : 0.001,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  // replaysOnErrorSampleRate: 1.0,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: environment.production ? 0.005 : 0.1,
  integrations: [
    // SentryAngular.replayIntegration(), Deactivate due to potential performance impact
    SentryAngular.browserTracingIntegration({
      beforeStartSpan: (context: any) => {
        // const cleanedName = location.pathname
        // .replace(/\/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/g, '/<databaseId>');
        const cleanedName = location.pathname.replace(/\/[a-f0-9]{24}/g, '/<hash>');
        return {
          ...context,
          name: cleanedName,
        };
      },
    }),
  ],
  tracePropagationTargets: [
    // Local Stuff
    'localhost',
    /^\/api/,
    // Mobile Interactions with remote servers
    'https://app.remberg.de/api',
    'https://staging.remberg.dev/api',
    'https://dev.remberg.dev/api',
    'https://test01.remberg.dev/api',
    'https://mirror.remberg.dev/api',
    /^https:\/\/[A-Za-z0-9](?:[A-Za-z0-9\\-]{0,61}[A-Za-z0-9])?.app.remberg.de\/api\/(.*)/g,
    /^https:\/\/[A-Za-z0-9](?:[A-Za-z0-9\\-]{0,61}[A-Za-z0-9])?.staging.remberg.dev\/api\/(.*)/g,
    /^https:\/\/[A-Za-z0-9](?:[A-Za-z0-9\\-]{0,61}[A-Za-z0-9])?.dev.remberg.dev\/api\/(.*)/g,
    /^https:\/\/[A-Za-z0-9](?:[A-Za-z0-9\\-]{0,61}[A-Za-z0-9])?.test01.remberg.dev\/api\/(.*)/g,
    /^https:\/\/[A-Za-z0-9](?:[A-Za-z0-9\\-]{0,61}[A-Za-z0-9])?.mirror.remberg.dev\/api\/(.*)/g,
  ],
});

// Starting the Application

if (environment.production) {
  // eslint-disable-next-line no-console
  console.log('Enabling prod mode for angular.');
  enableProdMode();
} else {
  // eslint-disable-next-line no-console
  console.log('Debug Log Environment: ' + JSON.stringify(environment ?? {}));
}

// show a disclaimer for not supported browsers (version is defined)
if (!isValidBrowser && (browser as any).parsedResult.browser.version) {
  // eslint-disable-next-line no-console
  console.log('browser not supported!');
  // eslint-disable-next-line no-console
  console.log(
    'You are using ' +
      (browser as any).parsedResult.browser.name +
      ' v' +
      (browser as any).parsedResult.browser.version,
  );
  if (!isPlatform('electron') && !isPlatform('capacitor')) {
    document.open();
    document.write(
      'You are using ' +
        (browser as any).parsedResult.browser.name +
        ' v' +
        (browser as any).parsedResult.browser.version +
        '.<br>This browser and version are not yet supported by remberg.<br>' +
        'Please consider switching to the latest version of Chrome, Firefox, Safari or Microsoft Edge.',
    );
    document.close();
  }
} else {
  if (environment.debug) {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .then((module) => {
        const applicationRef = module.injector.get(ApplicationRef);
        const appComponent = applicationRef.components[0];
        // eslint-disable-next-line no-console
        console.log('Enabling debug tools for angular.');
        enableDebugTools(appComponent);
      })
      // eslint-disable-next-line no-console
      .catch((err) => window['console'].error(err));
  } else {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  }
}
