import { Directive, OnDestroy, ProviderToken, inject } from '@angular/core';
import { EffectSources } from '@ngrx/effects';
import { Action, ActionReducerMap, ReducerManager, Store } from '@ngrx/store';

/**
 * This class serves to allow the creation of Standalone components with dedicated
 * feature states. However this only serves as a temporary workaround until ngrx
 * officially supports this paradigm (excluding the provision of state through a
 * route). Thus this Base class in only intended for specialized use cases.
 */
@Directive()
export abstract class BaseStandaloneNgrxComponent<
  S extends Record<string, object>,
  E extends ProviderToken<unknown>[],
> implements OnDestroy
{
  private readonly reducerManager: ReducerManager;
  private readonly effectSources: EffectSources;
  protected readonly store: Store<S>;

  constructor(
    private readonly effects: E,
    private readonly reducers: ActionReducerMap<S, Action>,
    protected readonly effectsUnsubscriptionTrigger: Action,
  ) {
    this.reducerManager = inject(ReducerManager);
    this.effectSources = inject(EffectSources);
    this.store = inject(Store);
    for (const [storeName, reducer] of Object.entries(reducers)) {
      this.reducerManager.addReducer(storeName, reducer);
    }
    const effectInstances = this.effects.map((effect) => inject(effect));
    for (const effect of effectInstances) {
      this.effectSources.addEffects(effect);
    }
  }

  public ngOnDestroy(): void {
    this.store.dispatch(this.effectsUnsubscriptionTrigger);
    for (const reducer of Object.keys(this.reducers)) {
      this.reducerManager.removeReducer(reducer);
    }
  }
}
