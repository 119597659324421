<ng-container *ngIf="isAskAiButtonVisible$ | async">
  <button
    mat-stroked-button
    class="ask-ai-button"
    (click)="askAi()"
    [matTooltip]="translations.openAskAIInSidebar"
  >
    <mat-icon class="ai-icon">auto_awesome</mat-icon>
    <span i18n="@@askAI">Ask AI</span>
  </button>
  <mat-sidenav
    #sidenav
    [autoFocus]="false"
    [opened]="!!(isSidebarOpen$ | async)"
    class="overview-sidenav mat-card mat-elevation-z4"
    position="end"
    [fixedInViewport]="true"
    [mode]="'over'"
    data-test-id="app-ask-ai-button-mat-sidenav"
  >
    <app-ask-ai-overlay-wrapper *ngIf="!!(isSidebarOpen$ | async)" />
  </mat-sidenav>
</ng-container>
