export enum AiChatContextTypeEnum {
  ASSET = 'asset',
  ASSET_TYPE = 'assetType',
  FILE = 'file',
}

export interface AiChatContext {
  instanceId: string;
  type: AiChatContextTypeEnum;
}
