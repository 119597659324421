import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationRef, ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NotificationsPopupComponent } from '@app/core/interface/notifications-popup/notifications-popup.component';
import { NotificationsComponent } from '@app/core/interface/notifications/notifications.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { LetDirective } from '@ngrx/component';
import { AskAiButtonComponent } from '@remberg/ai/ui/ai-shared-global-ask-ai';
import {
  CustomMatPaginatorIntl,
  DEFAULT_DROPZONE_CONFIG,
  INTERCOM_CONFIG,
  MaterialModule,
  TOASTR_CONFIG,
  environment,
  setAppInjector,
} from '@remberg/global/ui';
import { CoreUIModule, CustomRouteReuseStrategy, GlobalErrorHandler } from '@remberg/ui-core/core';
import { SharedMainModule, ToastrComponent } from '@remberg/ui-core/shared-main';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import localeCode from '../locale/code.locale';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalLoadingIndicatorComponent } from './core/interface/global-loading-indicator/global-loading-indicator.component';
import { InterfaceComponent } from './core/interface/interface.component';
import { LandingPortalNavbarComponent } from './core/interface/landing-portal-navbar/landing-portal-navbar.component';
import { LandingPortalComponent } from './core/interface/landing-portal/landing-portal.component';
import { LandingComponent } from './core/interface/landing/landing.component';
import { ActivationComponent } from './pages/activation/activation.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PasswordExpirationComponent } from './pages/password-expiration/password-expiration.component';
import { SetpwdComponent } from './pages/setpwd/setpwd.component';
import { TenantLoginComponent } from './pages/tenant-login/tenant-login.component';
import { UpdateRequiredComponent } from './pages/update-required/update-required.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';

// register a copy of en-US named as 'code' as an additional locale for local development
registerLocaleData(localeCode);

const APP_MODULE_DECLARATIONS = [
  AppComponent,
  // Interface Components
  InterfaceComponent,
  LandingComponent,
  LandingPortalNavbarComponent,
  LandingPortalComponent,
  NotificationsComponent,
  NotificationsPopupComponent,
  GlobalLoadingIndicatorComponent,
  // Landing Pages
  LoginComponent,
  ForgotComponent,
  SetpwdComponent,
  ActivationComponent,
  PasswordExpirationComponent,
  TenantLoginComponent,
  UpdateRequiredComponent,
  // Page not found
  PageNotFoundComponent,
  // Public pages
  VerifyEmailComponent,
];

@NgModule({
  declarations: APP_MODULE_DECLARATIONS,
  imports: [
    CommonModule,
    BrowserModule,
    MaterialModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    DragDropModule,
    ScrollingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.mobile,
    }),
    // Third-Party
    ImgFallbackModule,
    ToastrModule.forRoot({
      ...TOASTR_CONFIG,
      toastComponent: ToastrComponent,
    }),
    IntercomModule.forRoot(INTERCOM_CONFIG),
    LetDirective,
    // Quill Readme: HINT: If you are using lazy loading modules,
    // you have to add QuillModule.forRoot() to your imports in your root module
    // to make sure the Config services is registered.
    QuillModule.forRoot(),
    NgxUiLoaderModule,
    FlexLayoutModule,
    // remberg
    CoreUIModule,
    SharedMainModule,
    AskAiButtonComponent,
  ],
  providers: [
    /* {
            provide: LOCALE_ID,
            deps: [LanguageService],
            useFactory: (languageService) => languageService.getCurrentLanguage(),
        }, */
    { provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    // The following error handler also includes the Sentry error handler.
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(applicationRef: ApplicationRef, injector: Injector) {
    setAppInjector(injector);
  }
}
