import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LogService, WorkOrderRightsEnum } from '@remberg/global/ui';
import { ToastrService } from 'ngx-toastr';
import { UserRightsService } from '../../services/user-rights.service';

@Injectable({
  providedIn: 'root',
})
export class WorkOrdersGuard implements CanActivate, CanActivateChild {
  constructor(
    private userRightsService: UserRightsService,
    private router: Router,
    private logger: LogService,
    private toastr: ToastrService,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): MaybeAsync<GuardResult> {
    if (this.userRightsService.getPermission(WorkOrderRightsEnum.WORK_ORDER_ENABLED)) {
      this.logger.debug()('Work Order Guard: Successfully redirecting and resolving.');
      return true;
    }
    this.logger.info()('Work Order Guard: Redirecting from: ' + state.url);
    this.toastr.warning(
      $localize`:@@noWorkOrderFeature:Account feature "Work Order" is not granted.`,
      $localize`:@@warning:Warning`,
    );

    return this.router.parseUrl('/');
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): MaybeAsync<GuardResult> {
    return this.canActivate(route, state);
  }
}
