export enum AdvancedFilterOperatorEnum {
  IS = 'is',
  IS_NOT = 'isNot',
  IS_EMPTY = 'isEmpty',
  IS_NOT_EMPTY = 'isNotEmpty',
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'doesNotContain',
  FROM = 'from',
  UNTIL = 'until',
  GREATER_EQUAL_THAN = 'greaterEqualThan',
  LESS_EQUAL_THAN = 'lessEqualThan',
  IN = 'in',
}
