import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, of } from 'rxjs';
import { AiChatInputSource } from './ai-chat-input-source';

export interface BaseAskAiOverlayState {
  isInitialized: boolean;
  id?: string;
}

@Injectable()
export abstract class BaseAskAiOverlayStore<
  T extends BaseAskAiOverlayState,
> extends ComponentStore<T> {
  public readonly selectIsIntialized$ = this.select(({ isInitialized }) => isInitialized);
  public readonly selectId$ = this.select(({ id }) => id);
  public readonly selectIsGroupSourcesByFileEnabled$ = of(true);
  public readonly selectAiUserName$ = of($localize`:@@xrmAiCopilot:XRM AI Copilot`);
  public readonly selectChatId$ = of('chatWithFeature');
  public readonly selectSources$: Observable<AiChatInputSource[]> = of([]);
  public readonly selectAiProfileImageBase64$ = of('/assets/img/placeholder/ai_wand.svg');

  public readonly initialize$ = this.updater(
    (state, id?: string): T => ({
      ...state,
      isInitialized: id === state.id,
      id,
    }),
  );

  public previewFile?(): Promise<void>;
}
