import { MultiLanguageText } from '@remberg/global/common/core';
import { BreakpointEnum } from './enums';

export enum TableColumnDisplayOptionEnum {
  EXACT = 'exact',
  RELATIVE = 'relative',
}

export interface TableColumnDisplayOption {
  value: TableColumnDisplayOptionEnum;
  label: string;
  tooltip?: string;
  tooltipDate?: boolean;
  tooltipDatetime?: boolean;
}

interface TableColumnBase<T extends string> {
  /* Column unique identifier */
  identifier: T;
  /* Text to display as a label for each Column */
  label: string;

  /* Should not be set manually in config - comes from CP response */
  customPropertyId?: string;
  /* Dictates how the column is rendered */
  /* Should not be set manually in config- comes from CP response*/
  customPropertyType?: RenderedCustomPropertyTypeEnum;
  /* Formatting metadata for INPUT_NUMBER custom property */
  customPropertyInputNumberDisplayPrecision?: number;
  /* Options for SELECT custom property to map agains the selected one */
  customPropertyInputSelectOptions?: { id: string; label: MultiLanguageText }[];
}

export interface TableColumnConfigItem<T extends string> extends TableColumnBase<T> {
  /** Presentation detail available to select by user */
  displayOptions?: TableColumnDisplayOption[];
  /**
   * Index, at which column will be displayed in table if it's configured by default.
   * Not selectable by user or counted towards selectable/breakpoint columns limit.
   * Meant for utility column like asset image or options button.
   */
  fixedIndex?: number;

  /**
   * Should be true if the column is to be omitted in current session.
   * Eg isDisabled: !isTenantOwner || !isSomeFeatureEnabled || !hasSomePermission
   */
  isDisabled?: boolean;

  /** @deprecated use isDisabled instead. Should only Oem users be able to select the Column */
  onlyOem?: boolean;
  /** @deprecated use isDisabled instead. Should be only available for selection when the FF is in correct state */
  featureFlagOnly?: boolean;
  /** @deprecated use isDisabled instead. Should be only available for selection when the permission is in correct state */
  permissionOnly?: boolean;
}

/** Represents properties adjustable by user or per view/layout */
export interface TableColumnData<T extends string> {
  identifier: T;
  displayOption?: TableColumnDisplayOptionEnum;
}

export interface TableColumn<T extends string> extends TableColumnBase<T>, TableColumnData<T> {}

export type TableColumnsConfig<T extends string> = { [key in T]: TableColumnConfigItem<T> };

export type TableColumnsLimitConfig = Partial<Record<BreakpointEnum, number>>;

export enum RenderedCustomPropertyTypeEnum {
  TEXT = 'text',
  URL = 'url',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  REMBERG_DATE = 'rembergDate',
  REMBERG_DATETIME = 'rembergDatetime',
  SELECT = 'select',
  CONTACT = 'contact',
  ORGANIZATION = 'organization',
  ASSET = 'asset',
}

export const COLUMN_LIMIT_NO_COLUMNS = -1;
export const COLUMN_LIMIT_MOBILE = 2;
export const COLUMN_LIMIT_TABLET = 5;
export const COLUMN_LIMIT_LAPTOP = 7;
export const COLUMN_LIMIT_DESKTOP = 9;

export const COLUMN_LIMIT_MIN = 1;
export const COLUMN_LIMIT_MAX = COLUMN_LIMIT_DESKTOP;
export const COLUMN_INDEX_END = 100;

export function buildTableColumns<T extends string>(
  columnsData: TableColumnData<T>[],
  config: TableColumnsConfig<T>,
): TableColumn<T>[] {
  return columnsData
    .filter((columnAdjustables) => !!config[columnAdjustables.identifier])
    .map((columnAdjustables) => buildTableColumn(columnAdjustables, config));
}

export function buildTableColumn<T extends string>(
  { identifier, displayOption }: TableColumnData<T>,
  config: TableColumnsConfig<T>,
): TableColumn<T> {
  const {
    label,
    displayOptions,
    customPropertyId,
    customPropertyType,
    customPropertyInputNumberDisplayPrecision,
    customPropertyInputSelectOptions,
  } = config[identifier];
  const column: TableColumn<T> = {
    identifier,
    label,
    customPropertyId,
    customPropertyType,
    customPropertyInputNumberDisplayPrecision,
    customPropertyInputSelectOptions,
  };

  if (displayOptions?.length) {
    column.displayOption = displayOption ?? displayOptions[0].value;
  }

  return column;
}

export function filterInaccessibleTableColumns<T extends string>(
  config: TableColumnsConfig<T>,
  isOem?: boolean,
): TableColumnsConfig<T> {
  return Object.values<TableColumnConfigItem<T>>(config)
    .filter(
      (columnConfig) =>
        !columnConfig.isDisabled &&
        (isOem || !columnConfig.onlyOem) &&
        columnConfig.featureFlagOnly !== false &&
        columnConfig.permissionOnly !== false,
    )
    .reduce(
      (resultConfig, columnConfig) => ({
        ...resultConfig,
        [columnConfig.identifier]: columnConfig,
      }),
      {} as TableColumnsConfig<T>,
    );
}

export function applyFixedTableColumns<T extends string>(
  columns: TableColumn<T>[],
  fixedColumns: TableColumn<T>[],
  config: TableColumnsConfig<T>,
): TableColumn<T>[];

export function applyFixedTableColumns<T extends string>(
  columns: TableColumnData<T>[],
  fixedColumns: TableColumnData<T>[],
  config: TableColumnsConfig<T>,
): TableColumnData<T>[];

export function applyFixedTableColumns<T extends string>(
  columns: TableColumnData<T>[] | TableColumn<T>[],
  fixedColumns: TableColumnData<T>[] | TableColumn<T>[],
  config: TableColumnsConfig<T>,
): TableColumnData<T>[] | TableColumn<T>[] {
  const negativeFixedColumns = [];
  const positiveFixedColumns = [];

  for (const column of fixedColumns) {
    const fixedIndex = config[column.identifier].fixedIndex;
    if (fixedIndex !== undefined && fixedIndex < 0) {
      negativeFixedColumns.push(column);
    }
    if (fixedIndex !== undefined && fixedIndex >= 0) {
      positiveFixedColumns.push(column);
    }
  }

  negativeFixedColumns.sort((prevColumn, nextColumn) => {
    const prevIndex = config[prevColumn.identifier].fixedIndex as number;
    const nextIndex = config[nextColumn.identifier].fixedIndex as number;
    return prevIndex - nextIndex;
  });

  return positiveFixedColumns.reduce(
    (combinedColumns, column) => [
      ...combinedColumns.slice(0, config[column.identifier].fixedIndex),
      column,
      ...combinedColumns.slice(config[column.identifier].fixedIndex),
    ],
    [...negativeFixedColumns, ...columns],
  );
}

/**
 * Ensures that TableColumnData[] coming from not trusted source (like url params or potentially outdated custom view)
 * finally contains valid fixedIndex (non configurable) columns, based on default set of columns of given use case.
 *
 * If inputTableColumnsData is empty return defaults.
 */
export function ensureFixedColumnsData<T extends string>(
  inputTableColumnsData: TableColumnData<T>[] | undefined,
  defaultTableColumnsData: TableColumnData<T>[],
  config: TableColumnsConfig<T>,
): TableColumnData<T>[] {
  if (!inputTableColumnsData?.length) return defaultTableColumnsData;

  const defaultFixedColumnsData = defaultTableColumnsData.filter((column) =>
    isFixedColumn(column, config),
  );
  const nonFixedTableColumnsData = inputTableColumnsData.filter(
    (column) => !isFixedColumn(column, config),
  );
  return applyFixedTableColumns(nonFixedTableColumnsData, defaultFixedColumnsData, config);
}

function isFixedColumn<T extends string>(
  column: TableColumnData<T>,
  config: TableColumnsConfig<T>,
): boolean {
  return config[column.identifier]?.fixedIndex !== undefined;
}
