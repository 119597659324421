<div class="dialog-header-container">
  <div class="dialog-title-container">
    <mat-icon class="arrow-back" (click)="handleDialogCancel()">arrow_back</mat-icon>
    <h2 class="dialog-title-header" i18n="@@sort">Sort</h2>
  </div>

  <div class="dialog-actions">
    <button
      mat-button
      color="primary"
      data-test-id="sort-done"
      [disabled]="doneButtonDisabled$ | async"
      (click)="handleDialogApply()"
      i18n="@@done"
    >
      Done
    </button>
  </div>
</div>

<div
  class="sort-container"
  [ngClass]="{
    'sort-container-ios': (deviceType$ | async)?.isIos,
    'sort-container-android': (deviceType$ | async)?.isAndroid,
  }"
>
  <!-- Direction Selection -->
  <div class="direction-button-wrapper">
    <button
      mat-stroked-button
      class="direction-selection-button"
      *ngFor="let sortDirectionOption of sortDirectionOptions | keyvalue"
      [class.direction-selection-button-active]="currentSortDirection === sortDirectionOption.key"
      (click)="onSortDirectionChanged(sortDirectionOption.value?.identifier)"
      attr.data-test-id="sort-direction-{{ sortDirectionOption.key }}"
    >
      {{ sortDirectionOption.value?.label }}
    </button>
    <button
      *ngIf="isManual"
      mat-stroked-button
      class="direction-selection-button"
      [class.direction-selection-button-active]="isManual"
      [disabled]="true"
      data-test-id="option-direction-manual"
    >
      {{ MANUAL_SORT_LABEL }}
    </button>
  </div>

  <!-- Key Selection -->
  <div class="key-button-wrapper">
    <div
      *ngFor="let sortField of sortFields | keyvalue"
      class="key-option mat-elevation-z4 card is-clickable"
      [ngClass]="{ 'key-option-active': currentSortField === sortField.value?.identifier }"
      (click)="onSortFieldChanged(sortField.value?.identifier)"
      attr.data-test-id="option-key-{{ sortField.key }}"
    >
      <div class="table-column-text-ellipsis">
        {{ sortField.value?.label }}
      </div>

      <mat-icon *ngIf="currentSortField === sortField.value?.identifier" class="option-icon">
        check
      </mat-icon>
    </div>
  </div>

  <div class="mobile-dialog-button-wrapper" fxLayout="column">
    <button
      mat-button
      *ngIf="currentSortField || currentSortDirection"
      class="clear-all-button"
      color="warn"
      data-test-id="clear-all"
      (click)="reset()"
    >
      <ng-container *ngIf="!defaultSort" i18n="@@clearAll">Clear all</ng-container>
      <ng-container *ngIf="defaultSort" i18n="@@resetToDefault">Reset to default</ng-container>
    </button>
  </div>
</div>
