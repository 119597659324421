<div class="rb-card card">
  <div class="header">
    <div class="text">
      <ng-content select="[slot=header-text]"></ng-content>
    </div>

    <div class="icon">
      <ng-content select="[slot=header-icon]"></ng-content>
    </div>
  </div>

  <h3 class="title">
    <ng-content select="[slot=title]"></ng-content>
  </h3>

  <div class="content" *ngIf="hasContent">
    <ng-content select="[slot=content]"> </ng-content>
  </div>

  <div class="footer">
    <div class="left">
      <ng-content select="[slot=bottom-left-corner]"></ng-content>
    </div>

    <div class="right">
      <ng-content select="[slot=bottom-right-corner]"></ng-content>
    </div>
  </div>
</div>
