import { AssetStatusEnum } from '@remberg/assets/common/main';
import { ContactBasic } from '@remberg/crm/common/base';
import { ContactCompatibility, OrganizationCompatibility } from '@remberg/crm/common/main';
import { PlatformFile } from '@remberg/files/common/main';
import { CreateContextNew } from '@remberg/global/common/core';
import { CaseSummaryInterface } from '@remberg/tickets/common/base';
import {
  Activity,
  EmailMessageActivity,
  MessageActivity,
  PortalMessageActivity,
} from './case-activity.model';
import {
  CaseCustomPropertyPayloadValue,
  CaseCustomPropertyValue,
} from './case-custom-property.model';
import { CaseSummaryPayload } from './case-summary-payload.interface';
import { Category } from './category.model';
import { QuotationRequestPayload } from './quotation-request-payload.interface';
import { QuotationRequestInterface } from './quotation-request.interface';

export const QUOTATION_REQUEST = 'quotationRequest';

export interface ServiceCase {
  _id: string;
  ticketID: number;
  status: ServiceCaseStatus;
  assets?: ({ _id: string } | string)[]; // (Product | string)[];
  assetTypes?: ({ _id: string } | string)[]; // (ProductType | string)[];
  category?: Category | string;
  type?: ServiceCaseType;

  sender?: OrganizationCompatibility | string;
  receiver: OrganizationCompatibility | string;
  tenantId: string;

  creatorEmailAddress?: string;
  supportEmailAddress?: string;
  assignee?: ContactCompatibility | string;
  requestingContact: ContactCompatibility | string;
  followers?: (ContactCompatibility | string)[];

  closeTimeStamp?: Date;

  subject?: string;
  description?: string;

  oemPriority?: ServiceCasePriority;
  customerPriority?: ServiceCasePriority;
  resultingProductStatus?: AssetStatusEnum;
  customProperties?: CaseCustomPropertyValue[];

  oemRead?: boolean;
  customerRead?: boolean;

  activities: Activity[];

  oemFeedback?: boolean;

  createContext?: CreateContextNew;
  threadId?: string;
  slaStatus?: string;
  assetErrorCode?: string;
  lastModified?: Date;
  createdAt?: Date;

  newActivity?: any;
  feedbackActivity?: any;
  hasFailedMessage?: boolean;

  isMovePrevented?: boolean;
  isMoveUndoPrevented?: boolean;

  promptActivity?: string;

  __v?: number;

  nonEmbeddedImageIds?: string[];
  activityCount?: number;
  involvedContacts?: ContactBasic[];
  quotationRequest?: QuotationRequestInterface;
  summary?: CaseSummaryInterface;
}

export interface ServiceCasePayload
  extends Partial<Omit<ServiceCase, 'customProperties' | 'quotationRequest' | 'summary'>> {
  quotationRequest?: QuotationRequestPayload;
  markAllAsPrivate?: boolean;
  transformToInternalMessages?: string[];
  inReplyTo?: string;
  customProperties?: CaseCustomPropertyPayloadValue[];
  summary?: CaseSummaryPayload;
  shouldFollow?: boolean;
}

export interface ServiceCaseReplyOrForwardAction {
  type: 'reply' | 'replyAll' | 'forward' | 'edit' | 'aiDraft';
  message: MessageActivity | EmailMessageActivity | PortalMessageActivity;
  files?: PlatformFile[];
  context?: string;
}

export enum ServiceCaseType {
  QUESTION = 'question', // ServiceCase is created by an external source (Eg. Email)
  REQUEST = 'request',
  PROBLEM = 'problem',
  FEEDBACK = 'feedback',
  OPPORTUNITY = 'opportunity',
  OTHER = 'other',
}

export enum ServiceCaseStatus {
  NEW = '000_new', // Ticket is created by an external source (Eg. Email)
  OPEN = '010_open',
  PENDING = '020_pending',
  SOLVED = '030_solved',
  CLOSED = '040_closed',
  MOVED = '050_moved',
}

export enum ServiceCasePriority {
  LOW = '000_low',
  NORMAL = '010_normal',
  HIGH = '020_high',
  CRITICAL = '030_critical',
}

export enum ServiceCaseViews {
  ALL = 'all',
  UNSOLVED = 'unsolved',
  SOLVED = 'solved',
  UNASSIGNED = 'unassigned',
  MYUNSOLVED = 'myunsolved',
}

export enum CaseViewTypeEnum {
  MYUNSOLVED = 'myunsolved',
  UNSOLVED = 'unsolved',
  UNASSIGNED = 'unassigned',
  SOLVED = 'solved',
  ALL = 'all',
}

/** Allowed field to perform filtering from API */
export enum CaseAPIFilterFieldEnum {
  ASSET = 'asset',
  ASSET_TYPE = 'assetType',
  ASSIGNEE = 'assignee',
  SENDER = 'sender',
  PRIORITY = 'priority',
  REQUESTER = 'requester',
  SOURCE_TYPE = 'sourceType',
  STATUS = 'status',
  TYPE = 'type',
  CATEGORY = 'category',
  COMPLETED = 'completed',
  SUPPORT_EMAIL = 'supportEmail',
  REQUESTER_EMAIL = 'requesterEmail',
  CREATED = 'created',
  UPDATED = 'updated',
  ID = '_id',
  QUOTATION_REQUEST = 'quotationRequest',
  QUOTATION_REQUEST_EXTERNAL_ID = 'quotationRequest.externalId',
  SUMMARY = 'summary',
}

/** Allowed field to perform sorting*/
export enum CaseSortFieldEnum {
  COUNTER = 'counter',
  SUBJECT = 'subject',
  STATUS = 'status',
  CREATED = 'created',
  UPDATED = 'updated',
  PRIORITY = 'priority',
  SUPPORT_EMAIL_ADDRESS = 'supportEmailAddress',
  SOURCE = 'source',
}

export interface CaseExportResponse {
  file: PlatformFile;
  activityFileIds?: string[];
  hasError: boolean;
}

export enum CasePopulateFieldEnum {
  ASSIGNEE = 'assignee',
  ASSETS = 'assets',
  REQUESTING_CONTACT = 'requestingContact',
  SENDER = 'sender',
  CATEGORY = 'category',
  RECEIVER = 'receiver',
}
