export interface CardFieldConfigItem<T extends string> {
  /* Column unique identifier */
  identifier: T;

  /**
   * Should be true if the column is to be omitted in current session.
   * Eg isDisabled: !isTenantOwner || !isSomeFeatureEnabled || !hasSomePermission
   */
  isDisabled?: boolean;
}

export type CardFieldConfig<T extends string> = { [key in T]: CardFieldConfigItem<T> };

export function filterInaccessibleCardFields<T extends string>(
  config: CardFieldConfig<T>,
): CardFieldConfig<T> {
  return Object.values<CardFieldConfigItem<T>>(config)
    .filter((fieldConfig) => !fieldConfig.isDisabled)
    .reduce(
      (resultConfig, columnConfig) => ({
        ...resultConfig,
        [columnConfig.identifier]: columnConfig,
      }),
      {} as CardFieldConfig<T>,
    );
}

export function getCardFields<T extends string>(
  config: CardFieldConfig<T>,
  displayCardFields: T[],
): T[] {
  return displayCardFields.filter((field) => config[field]);
}
