import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchButtonComponent {
  @Input() public inputValue?: string;
  @Input() public inputPlaceholder?: string = $localize`:@@searchDotDotDot:Search ...`;
  @Output() public searchQueryChange = new EventEmitter<string>();

  protected readonly showSearchBar$ = new BehaviorSubject<boolean>(false);
  protected readonly isSearchBarShown$ = this.showSearchBar$.pipe(
    // Delay is needed when clicking on the search input clear button to prevent the search bar from closing to fast
    debounceTime(100),
  );
}
