import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AdvancedFilterConcatOperatorEnum,
  AdvancedFilterOperatorEnum,
} from '@remberg/advanced-filters/common/main';
import { ContactBasic } from '@remberg/crm/common/base';
import { ContactsFilterEnum } from '@remberg/crm/common/main';
import { ContactsService } from '@remberg/crm/ui/clients';
import { UnknownOr, isUnknownObject } from '@remberg/global/common/core';
import {
  ApiResponse,
  EmptyListType,
  MainRoutes,
  MultiSelectFunctions,
  MultiSelectModalOptionLabel,
} from '@remberg/global/ui';
import { Observable, map, of } from 'rxjs';
import {
  BaseMultiselectComponent,
  BaseMultiselectComponentTranslations,
} from '../base-multiselect/base-multiselect.component';

@Component({
  selector: 'app-contact-multiselect',
  templateUrl: '../base-multiselect/base-multiselect.component.html',
  styleUrls: ['../base-multiselect/base-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactMultiselectComponent extends BaseMultiselectComponent<UnknownOr<ContactBasic>> {
  @Input() public titleIcon: string = 'person';
  @Input() public titleIconSvg = '';
  @Input() public translations: BaseMultiselectComponentTranslations = {
    title: $localize`:@@contacts:Contacts`,
  };

  @Input() public organizationId?: string;
  @Input() public showEmailInLabel?: boolean;

  protected readonly emptyListType: EmptyListType = 'users';

  private getModalOptionLabel = (
    contact: UnknownOr<ContactBasic>,
  ): MultiSelectModalOptionLabel => ({
    label: isUnknownObject(contact) ? '' : `${contact.firstName} ${contact.lastName}`,
    subLabel: isUnknownObject(contact) ? '' : contact.primaryEmail,
  });

  private getLabelWithEmail = (contact: UnknownOr<ContactBasic>): string =>
    isUnknownObject(contact)
      ? ''
      : `${contact.firstName} ${contact.lastName} (${contact.primaryEmail})`;

  private getOptionLabel = (contact: UnknownOr<ContactBasic>): string =>
    this.showEmailInLabel
      ? this.getLabelWithEmail(contact)
      : this.getModalOptionLabel(contact).label;

  private getChipLabel = (contact: UnknownOr<ContactBasic>): string => this.getOptionLabel(contact);

  private getChipLink = (contact: UnknownOr<ContactBasic>): Observable<string> =>
    of(isUnknownObject(contact) ? '' : `/${MainRoutes.CONTACTS}/preview/${contact._id}`);

  private fetchItemsWithCount = (
    search: string,
    pageSize: number,
    pageOffset: number,
  ): Observable<ApiResponse<ContactBasic[]>> =>
    this.contactsService
      .findManyWithCountBasic({
        search,
        page: pageOffset,
        limit: pageSize,
        ...(this.organizationId && {
          filterObject: {
            concatOperator: AdvancedFilterConcatOperatorEnum.AND,
            filters: [
              {
                identifier: ContactsFilterEnum.ORGANIZATION,
                operator: AdvancedFilterOperatorEnum.IS,
                value: this.organizationId,
              },
            ],
          },
        }),
      })
      .pipe(
        map((res) => ({
          data: res.contacts,
          count: res.count,
        })),
      );

  protected readonly functions: MultiSelectFunctions<UnknownOr<ContactBasic>> = {
    getChipLabel: this.getChipLabel,
    getChipLink: this.getChipLink,
    getOptionLabel: this.getOptionLabel,
    getModalOptionLabel: this.getModalOptionLabel,
    fetchListValuesWithCountFunction: this.fetchItemsWithCount,
  };

  constructor(private readonly contactsService: ContactsService) {
    super();
  }
}
