import {
  ADVANCED_FILTER_ARRAY_SEPARATOR,
  AdvancedFilter,
  AdvancedFilterOperatorEnum,
} from '@remberg/advanced-filters/common/main';
import { UnreachableCaseError, assertDefined } from '@remberg/global/ui';

export enum SQLConcatOperator {
  AND = 'AND',
  OR = 'OR',
}

export function sqlFiltersHelper<T extends string>(
  advancedFilter: AdvancedFilter<T>,
  columnName: string,
  isRembergDate = false,
): string {
  switch (advancedFilter.operator) {
    case AdvancedFilterOperatorEnum.IS:
      assertDefined(
        advancedFilter.value,
        'advancedFilter.value should be defined for filter operator is',
      );
      return generateEqualsSQLFilter(columnName, advancedFilter.value);
    case AdvancedFilterOperatorEnum.IS_NOT:
      assertDefined(
        advancedFilter.value,
        'advancedFilter.value should be defined for filter operator is not',
      );
      return generateNotEqualsSQLFilter(columnName, advancedFilter.value);
    case AdvancedFilterOperatorEnum.IS_EMPTY:
      return generateIsEmptySQLFilter(columnName);
    case AdvancedFilterOperatorEnum.IS_NOT_EMPTY:
      return generateNotEmptySQLFilter(columnName);
    case AdvancedFilterOperatorEnum.CONTAINS:
      assertDefined(
        advancedFilter.value,
        'advancedFilter.value should be defined for filter operator contains',
      );
      return generateContainsSQLFilter(columnName, advancedFilter.value);
    case AdvancedFilterOperatorEnum.DOES_NOT_CONTAIN:
      assertDefined(
        advancedFilter.value,
        'advancedFilter.value should be defined for filter operator does not contain',
      );
      return generateDoesNotContainSQLFilter(columnName, advancedFilter.value);
    case AdvancedFilterOperatorEnum.FROM:
      assertDefined(
        advancedFilter.value,
        'advancedFilter.value should be defined for filter operator from',
      );
      return generateFromDateSQLFilter(columnName, advancedFilter.value, isRembergDate);
    case AdvancedFilterOperatorEnum.UNTIL:
      assertDefined(
        advancedFilter.value,
        'advancedFilter.value should be defined for filter operator until',
      );
      return generateUntilDateSQLFilter(columnName, advancedFilter.value, isRembergDate);
    case AdvancedFilterOperatorEnum.GREATER_EQUAL_THAN:
      assertDefined(
        advancedFilter.value,
        'advancedFilter.value should be defined for filter operator greater than',
      );
      return generateGreaterThanSQLFilter(columnName, advancedFilter.value);
    case AdvancedFilterOperatorEnum.LESS_EQUAL_THAN:
      assertDefined(
        advancedFilter.value,
        'advancedFilter.value should be defined for filter operator less than',
      );
      return generateLessThanSQLFilter(columnName, advancedFilter.value);
    case AdvancedFilterOperatorEnum.IN:
      assertDefined(
        advancedFilter.value,
        'advancedFilter.value should be defined for filter operator in',
      );
      return generateInArraySQLFilter(columnName, advancedFilter.value);
    default:
      throw new UnreachableCaseError(advancedFilter.operator);
  }
}

export function generateBooleanSQLFilterItemValue<T extends string>(
  advancedFilter: AdvancedFilter<T>,
  columnName: string,
): string {
  return `${columnName} = ${advancedFilter.value === 'true' ? 1 : 0}`;
}

export function generateEqualsSQLFilter(columnName: string, value: string): string {
  return `${columnName} = '${value}'`;
}

export function generateNotEqualsSQLFilter(columnName: string, value: string): string {
  return concatSQLFiltersByOperator(
    [`${columnName} != '${value}'`, generateIsEmptySQLFilter(columnName)],
    SQLConcatOperator.OR,
  );
}

export function generateContainsSQLFilter(columnName: string, value: string): string {
  return `${columnName} LIKE '%${value}%'`;
}

export function generateDoesNotContainSQLFilter(columnName: string, value: string): string {
  return concatSQLFiltersByOperator(
    [`${columnName} NOT LIKE '%${value}%'`, generateIsEmptySQLFilter(columnName)],
    SQLConcatOperator.OR,
  );
}

export function generateIsEmptySQLFilter(columnName: string): string {
  return concatSQLFiltersByOperator(
    [`${columnName} IS NULL`, `${columnName} LIKE ''`],
    SQLConcatOperator.OR,
  );
}

export function generateArrayContainsSQLFilter(columnName: string, values: string[]): string {
  return concatSQLFiltersByOperator(
    values.map((v) => `${columnName} LIKE '%${v}%'`),
    SQLConcatOperator.AND,
  );
}

export function generateArrayDoesNotContainSQLFilter(columnName: string, values: string[]): string {
  const negativeFilter = concatSQLFiltersByOperator(
    values.map((v) => `${columnName} NOT LIKE '%${v}%'`),
    SQLConcatOperator.AND,
  );

  const emptyFilter = generateIsEmptySQLFilter(columnName);

  return concatSQLFiltersByOperator([negativeFilter, emptyFilter], SQLConcatOperator.OR);
}

export function generateNotEmptySQLFilter(columnName: string): string {
  return concatSQLFiltersByOperator(
    [`${columnName} IS NOT NULL`, `${columnName} != ''`],
    SQLConcatOperator.AND,
  );
}

export function generateFromDateSQLFilter(
  columnName: string,
  value: string,
  isRembergDate = false,
): string {
  // apply the time to filter value so that gte operator works correctly when comparing strings
  const adjustedValue = !isRembergDate ? new Date(`${value}T00:00:00Z`).toISOString() : value;
  return `'${adjustedValue}' <= ${columnName}`;
}

export function generateUntilDateSQLFilter(
  columnName: string,
  value: string,
  isRembergDate = false,
): string {
  // apply the time to filter value so that lte operator works correctly when comparing strings
  const adjustedValue = !isRembergDate ? new Date(`${value}T23:59:59Z`).toISOString() : value;
  return `'${adjustedValue}' >= ${columnName}`;
}

export function generateGreaterThanSQLFilter(columnName: string, value: string): string {
  return `${castToInt(value)} >= ${castToInt(columnName)}`;
}

export function generateLessThanSQLFilter(columnName: string, value: string): string {
  return `${castToInt(value)} <= ${castToInt(columnName)}`;
}

export function concatSQLFiltersByOperator(
  filters: string[],
  concatOperator: SQLConcatOperator,
): string {
  return `(${filters.join(` ${concatOperator} `)})`;
}

function castToInt(property: string): string {
  return `CAST(${property} AS INT)`;
}

export function generateInArraySQLFilter(columnName: string, value: string): string {
  return `${columnName} IN ('${value.split(ADVANCED_FILTER_ARRAY_SEPARATOR).join("','")}')`;
}
