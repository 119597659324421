import { Assert, FeatureFlagEnum, TwoWayCompareStringType } from '@remberg/global/common/core';
import { TaskPermissions } from '@remberg/users/common/main';

export enum TasksPermissionEnum {
  TASKS_ENABLED = 'tasks_enabled',
  TASKS_UPDATE_ALL = 'tasks_updateAll',
  TASKS_DELETE_ALL = 'tasks_deleteAll',
}

export type AssertMatchingInterfaces = Assert<
  TwoWayCompareStringType<
    `${FeatureFlagEnum.TASKS}_${keyof TaskPermissions}`,
    `${TasksPermissionEnum}`
  >
>;
