import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { FormInstanceAdvancedFilterFieldEnum } from '@remberg/forms/common/main';
import { FilterType, GetManyPayload, PopulateQueryParam } from '@remberg/global/common/core';

export type FormInstanceTableQuery = GetManyPayload<
  AdvancedFilterQuery<FormInstanceAdvancedFilterFieldEnum>,
  AdvancedFilter<FormInstanceAdvancedFilterFieldEnum>,
  FormInstanceSortFieldEnum
> & {
  /**
   * @deprecated Use filterObject instead
   */
  filters?: FilterType<FormInstanceFilterFieldEnum>[]; // TODO S4-2105: leftover for compatibility
  populate?: PopulateQueryParam<FormInstancePopulateFieldEnum>;
};

/**
 * @deprecated Use advanced filters. See libs/forms/common/main/src/models/form-instance/enums/form-instance-advanced-filter-field.enum.ts
 */
export enum FormInstanceFilterFieldEnum {
  ASSIGNEE = 'assignee',
  CREATED_BY = 'createdBy',
  FORM_TEMPLATE = 'formTemplate',
  PDF_LANGUAGE = 'pdfLanguage',
  RELATED_ASSETS = 'relatedAssets',
  RELATED_ORGANIZATIONS = 'relatedOrganizations',
  RELATED_WORK_ORDER = 'relatedWorkOrder',
  RELATED_WORK_ORDER_2 = 'relatedWorkOrder2',
  STATUS = 'status',

  // Custom logic filters
  INVOLVED_USER = 'involvedUser',
  EMAILS = 'emails',

  // Used specifically for the customer facing API, TODO migrate to advanced filters
  FINALIZED_AT_FROM = 'finalizedAtFrom',
  FINALIZED_AT_UNTIL = 'finalizedAtUntil',
}

export enum FormInstanceSortFieldEnum {
  COUNTER = 'counter',
  NAME = 'name',
  DATE_MODIFIED = 'dateModified',
  FINALIZED_AT = 'finalizedAt',
  CREATED_AT = 'createdAt',
}

export enum FormInstancePopulateFieldEnum {
  ASSIGNEE = 'assignee',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  RELATED_ORGANIZATIONS = 'relatedOrganizations',
  RELATED_ASSETS = 'relatedAssets',
  RELATED_WORK_ORDER = 'relatedWorkOrder',
  RELATED_WORK_ORDER_2 = 'relatedWorkOrder2',
  FORM_TEMPLATE = 'formTemplate',
  FORM_TEMPLATE_VERSION = 'formTemplateVersion',
  EMAIL_STATUSES = 'emailStatuses',
}

export enum FormInstanceEmailFilterValueEnum {
  SENT = 'sent',
  NOT_SENT = 'notSent',
  NONE = 'none',
}
