import { Pipe, PipeTransform } from '@angular/core';
import { convertHtmlToPlainText } from '@remberg/global/common/core';

@Pipe({
  name: 'convertHtmlToPlainText',
  standalone: true,
})
export class ConvertHtmlToPlainTextPipe implements PipeTransform {
  public transform(value: string | undefined): string {
    return value ? convertHtmlToPlainText(value) : '';
  }
}
