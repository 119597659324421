import { createReducer, on } from '@ngrx/store';
import { AskAiActions } from './ask-ai.actions';
import { AskAiState } from './ask-ai.definitions';

export const INITIAL_ASK_AI_STATE: AskAiState = {
  isSidebarOpen: false,
};

export const askAiReducer = createReducer<AskAiState>(
  INITIAL_ASK_AI_STATE,

  on(
    AskAiActions.sidebarOpened,
    (state, { id, activeFeature }): AskAiState => ({
      ...state,
      isSidebarOpen: true,
      id,
      activeFeature,
    }),
  ),
  on(
    AskAiActions.sidebarClosed,
    (): AskAiState => ({
      ...INITIAL_ASK_AI_STATE,
    }),
  ),
);
