import { inject } from '@angular/core';
import { CanActivateFn, GuardResult, MaybeAsync, Router } from '@angular/router';
import { LogService } from '@remberg/global/ui';
import { WorkOrderPermissionsEnum } from '@remberg/work-orders/common/main';
import { UserRightsService } from '../../services/user-rights.service';

export const workOrders2GuardFn: CanActivateFn = (): MaybeAsync<GuardResult> => {
  const userRightsService = inject(UserRightsService);
  const router = inject(Router);
  const logger = inject(LogService);

  const isWorkOrders2FeatureAccessible = userRightsService.getPermission(
    WorkOrderPermissionsEnum.WORK_ORDERS_ENABLED,
  );

  if (isWorkOrders2FeatureAccessible) return true;

  logger.info()('Work Orders 2 Guard: Redirecting from: ' + router.url);
  return router.parseUrl('/');
};
