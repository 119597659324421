import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  SortDirectionEnum,
  UnknownOr,
  formatToRembergNumberString,
  isUnknownObject,
} from '@remberg/global/common/core';
import {
  ApiResponse,
  EmptyListType,
  MainRoutes,
  MultiSelectFunctions,
  MultiSelectModalOptionLabel,
} from '@remberg/global/ui';
import { WorkOrderBasic } from '@remberg/work-orders/common/base';
import { WorkOrderSortFieldEnum, mapToWorkOrderBasic } from '@remberg/work-orders/common/main';
import { WorkOrderService } from '@remberg/work-orders/ui/clients';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BaseMultiselectComponent,
  BaseMultiselectComponentTranslations,
} from '../base-multiselect/base-multiselect.component';

@Component({
  selector: 'app-work-order-2-multiselect',
  templateUrl: '../base-multiselect/base-multiselect.component.html',
  styleUrls: ['../base-multiselect/base-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkOrder2MultiselectComponent extends BaseMultiselectComponent<
  UnknownOr<WorkOrderBasic>
> {
  @Input() public titleIcon: string = 'handyman-work';
  @Input() public titleIconSvg = '';
  @Input() public translations: BaseMultiselectComponentTranslations = {
    title: $localize`:@@WorkOrders:Work Orders`,
  };

  protected readonly emptyListType: EmptyListType = 'workorders';

  private getModalOptionLabel = (
    workOrder: UnknownOr<WorkOrderBasic>,
  ): MultiSelectModalOptionLabel => ({
    label: isUnknownObject(workOrder) ? '' : (workOrder.subject ?? ''),
    subLabel: isUnknownObject(workOrder) ? '' : formatToRembergNumberString(workOrder.counter),
  });

  private getOptionLabel = (workOrder: UnknownOr<WorkOrderBasic>): string => {
    const optionLabel = this.getModalOptionLabel(workOrder);
    return `${optionLabel.label} (${optionLabel.subLabel})`;
  };

  private getChipLabel = (workOrder: UnknownOr<WorkOrderBasic>): string =>
    this.getOptionLabel(workOrder);

  private getChipLink = (workOrder: UnknownOr<WorkOrderBasic>): Observable<string> =>
    of(isUnknownObject(workOrder) ? '' : `/${MainRoutes.WORK_ORDERS_2}/detail/${workOrder._id}`);

  private fetchItemsWithCount = (
    search: string,
    pageSize: number,
    pageOffset: number,
  ): Observable<ApiResponse<WorkOrderBasic[]>> =>
    this.workOrderService
      .findMany({
        limit: pageSize,
        page: pageOffset,
        sortField: WorkOrderSortFieldEnum.COUNTER,
        sortDirection: SortDirectionEnum.ASC,
        search,
      })
      .pipe(
        map((response) => ({
          ...response,
          data: response.data.map(mapToWorkOrderBasic),
        })),
      );

  protected readonly functions: MultiSelectFunctions<UnknownOr<WorkOrderBasic>> = {
    getChipLabel: this.getChipLabel,
    getChipLink: this.getChipLink,
    getOptionLabel: this.getChipLabel,
    getModalOptionLabel: this.getModalOptionLabel,
    fetchListValuesWithCountFunction: this.fetchItemsWithCount,
  };

  constructor(private readonly workOrderService: WorkOrderService) {
    super();
  }
}
