import { QRCode } from '@remberg/assets/common/base';
import { createMapper } from '@remberg/global/common/core';
import { QRCodeLegacy } from '../definitions';

function mapQRCodeLegacyToQRCode(qrCode: QRCodeLegacy): QRCode {
  return {
    _id: qrCode._id,
    linkId: qrCode.linkId,
    targetId: qrCode.target,
    tenantId: qrCode.tenantId,
    createdAt: qrCode.creationDate,
    updatedAt: qrCode.creationDate, // in absence of a proper updatedAt field
    assignedAt: qrCode.assignmentDate,
    scanCount: qrCode.scanCount,
    isDownloaded: qrCode.downloaded,
    image: qrCode.image,
    tenantLabel: qrCode.tenantLabel,
  };
}

export const mapToQRCode = createMapper(mapQRCodeLegacyToQRCode);
