export const mapDurationInSecondsToDisplayString = (
  seconds?: number | null,
  emptyResult = '',
): string => {
  if (!seconds) return emptyResult;
  if (seconds < 60) return '< 1min';
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  /**
   * 1d 2h 3m
   * 1d 0h 3m
   * 2h 0m
   * 2h 3m
   * 3m
   * empty string
   */
  return `${days ? days + 'd' : ''} ${hours || days ? (hours % 24) + 'h' : ''} ${days || hours || minutes ? (minutes % 60) + 'min' : ''}`.trim();
};
