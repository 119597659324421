import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseStandaloneNgrxComponent, MaterialModule } from '@remberg/global/ui';
import { SharedMainModule } from '@remberg/ui-core/shared-main';
import {
  GLOBAL_ASK_AI_SHARED_EFFECTS,
  GLOBAL_ASK_AI_SHARED_STORE_NAME,
  RootAiSharedGlobalAskAiState,
  globalAskAiReducer,
} from '../../store';
import { AskAiActions, AskAiSelectors } from '../../store/ask-ai';
import { AskAiOverlayWrapperComponent } from './ask-ai-overlay-wrapper/ask-ai-overlay-wrapper.component';

@Component({
  selector: 'app-ask-ai-button',
  templateUrl: './ask-ai-button.component.html',
  styleUrls: ['./ask-ai-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...GLOBAL_ASK_AI_SHARED_EFFECTS],
  imports: [CommonModule, MaterialModule, SharedMainModule, AskAiOverlayWrapperComponent],
  standalone: true,
})
export class AskAiButtonComponent extends BaseStandaloneNgrxComponent<
  Omit<RootAiSharedGlobalAskAiState, 'global'>,
  typeof GLOBAL_ASK_AI_SHARED_EFFECTS
> {
  protected readonly isAskAiButtonVisible$ = this.store.select(
    AskAiSelectors.selectIsAskAiButtonVisible,
  );
  protected readonly isSidebarOpen$ = this.store.select(AskAiSelectors.selectIsSidebarOpen);
  protected readonly translations = {
    openAskAIInSidebar: $localize`:@@askAIInSidebar:Ask AI in Sidebar`,
  };

  constructor() {
    const actionReducerMap = { [GLOBAL_ASK_AI_SHARED_STORE_NAME]: globalAskAiReducer };
    super(GLOBAL_ASK_AI_SHARED_EFFECTS, actionReducerMap, AskAiActions.destroyed());
  }

  protected async askAi(): Promise<void> {
    this.store.dispatch(AskAiActions.openSidebarFromGlobalContext());
  }
}
