export enum WorkOrderFilterFieldEnum {
  ID = 'id',
  EXTERNAL_REFERENCE = 'externalReference',
  DUE_DATE = 'dueDate',

  STREET = 'street',
  CITY = 'city',
  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  ZIP_CODE = 'zipPostCode',

  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdBy',
  UPDATED_AT = 'updatedAt',
  UPDATE_BY = 'updateBy',

  TOUCHED = 'touched',

  RESPONSIBLE_CONTACT = 'responsibleContact',
  RESPONSIBLE_GROUP = 'responsibleGroup',

  PRIORITY = 'priority',
  STATUS = 'status',
  STATUS_COMPLETED = 'statusCompleted',
  TYPE = 'type',

  RELATED_CASE = 'relatedCase',
  RELATED_ORGANIZATION = 'relatedOrganization',
  RELATED_ASSET = 'relatedAsset',
  RELATED_ASSETS = 'relatedAssets',

  RELATED_CONTACT = 'relatedContact',
  MAINTENANCE_PLAN = 'maintenancePlan',

  PARENT_WORK_ORDER = 'parentWorkOrder',

  PLANNING_ASSIGNED_CONTACT = 'planningAssignedContact',
  PLANNING_ASSIGNED_GROUP = 'planningAssignedGroup',
  PLANNING_START_DATE = 'planningStartDate',
  PLANNING_END_DATE = 'planningEndDate',

  // Custom logic filters
  INVOLVED_CONTACT = 'involvedContact',
  MY_GROUPS = 'myGroups',
  BOUNDING_BOX = 'boundingBox',
  FAMILY = 'family',
}
