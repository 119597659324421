<div class="dialog-header-container">
  <div class="dialog-title-container">
    <h2 class="dialog-title-header" i18n="@@sort">Sort</h2>
  </div>
  <div class="dialog-actions clear-all-button">
    <button
      *ngIf="currentSortField || currentSortDirection"
      mat-button
      color="warn"
      (click)="reset()"
      data-test-id="clear-all"
    >
      <ng-container *ngIf="!defaultSort" i18n="@@clearAll">Clear all</ng-container>
      <ng-container *ngIf="defaultSort" i18n="@@resetToDefault">Reset to default</ng-container>
    </button>
  </div>
</div>

<mat-dialog-content class="dialog-content">
  <div class="content-wrapper">
    <mat-form-field appearance="outline" class="my-mat-form-field my-mat-form-field-outline">
      <mat-label i18n="@@selectKey">Select Key</mat-label>
      <mat-select
        [(value)]="currentSortField"
        attr.data-test-id="current-sort-field-{{ currentSortField ?? 'not-defined' }}"
        disableOptionCentering
        (selectionChange)="onSortFieldChanged()"
      >
        <mat-option
          *ngFor="let sortField of sortFields | keyvalue"
          [value]="sortField.value?.identifier"
          attr.data-test-id="option-key-{{ sortField.key }}"
        >
          {{ sortField.value?.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="my-mat-form-field my-mat-form-field-outline">
      <mat-label i18n="@@selectDirection">Select Direction</mat-label>
      <mat-select
        [(value)]="currentSortDirection"
        attr.data-test-id="current-sort-direction-{{ currentSortDirection ?? 'not-defined' }}"
        (selectionChange)="onSortDirectionChanged()"
      >
        <mat-option
          *ngFor="let sortDirectionOption of sortDirectionOptions | keyvalue"
          [value]="sortDirectionOption.value?.identifier"
          attr.data-test-id="option-direction-{{ sortDirectionOption.key }}"
        >
          {{ sortDirectionOption.value?.label }}</mat-option
        >
        <mat-option
          *ngIf="isManual"
          [value]="MANUAL_SORT_DIRECTION"
          [disabled]="true"
          data-test-id="option-direction-manual"
          >{{ MANUAL_SORT_LABEL }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>

<div class="dialog-footer-container sort-footer">
  <button mat-stroked-button (click)="handleDialogCancel()" data-test-id="cancel" i18n="@@cancel">
    Cancel
  </button>

  <button
    mat-raised-button
    color="primary"
    class="apply-button"
    [disabled]="applyButtonDisabled$ | async"
    (click)="handleDialogApply()"
    data-test-id="action"
    i18n="@@apply"
  >
    Apply
  </button>
</div>
