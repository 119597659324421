import { MatDialog } from '@angular/material/dialog';
import {
  Observable,
  OperatorFunction,
  filter,
  firstValueFrom,
  map,
  mergeMap,
  of,
  withLatestFrom,
} from 'rxjs';
import { ConfirmationDialogPayload, getConfirmationCancelMoveAway } from '../helpers';

/**
 * Specialized operator function based on filterCannotDeactivateUnlessDiscarded
 * for handling blocking actions based on user confirmation
 *
 * @param matDialog Reference to MatDialog service
 * @param dialogData Dialog config object for manipulating appearance and text of the
 * constituent elements
 * @param canBypass$ optional bypass for the warning
 */
export function filterUnlessConfirmed<T>(
  matDialog: MatDialog,
  dialogData?: ConfirmationDialogPayload,
  canBypass$ = of(false),
): OperatorFunction<T, T> {
  return (source$: Observable<T>): Observable<T> =>
    source$.pipe(
      withLatestFrom(canBypass$),
      mergeMap(async ([payload, canBypass]): Promise<[T, boolean]> => {
        if (canBypass) return [payload, true];

        const dialogRef = getConfirmationCancelMoveAway(matDialog, dialogData);
        const result = await firstValueFrom(dialogRef.afterClosed());
        return [payload, !!result?.isConfirmed];
      }),
      filter(([, proceed]) => !!proceed),
      map(([payload]) => payload),
    );
}
