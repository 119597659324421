import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  getStringID,
  LogService,
  SQLQueryParams,
  SQLSortDirection,
  SyncDataTypesEnum,
} from '@remberg/global/ui';
import { UserGroup } from '@remberg/users/common/main';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../../store';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService } from '../sqlDB.service';

const enum ColumnNamesEnum {
  NAME = 'name',
  ACCOUNT = 'account', // Never used for filter or sort -> could be removed
}

const params: SQLQueryParams<ColumnNamesEnum> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.USERGROUPS,
  columns: {
    [ColumnNamesEnum.NAME]: {
      type: 'TEXT',
      valueFunction: (val: UserGroup) => val.name?.trim(),
      sortNoCase: true,
    },
    [ColumnNamesEnum.ACCOUNT]: {
      type: 'TEXT',
      valueFunction: (val: UserGroup) => getStringID(val?.account),
    },
  },
};

@Injectable({
  providedIn: 'root',
})
export class UserGroupOfflineService extends BaseOfflineService<UserGroup, never> {
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, params, logger, store);
  }

  public async getUserGroupsByTenant(
    search?: string,
    ownGroupsOnly?: boolean,
  ): Promise<UserGroup[]> {
    // filters:
    const filterStrings = [];

    // search:
    if (search) {
      filterStrings.push(`${params.tableName}.${ColumnNamesEnum.NAME} LIKE '%${search}%'`);
    }

    // only the groups that the current user is assigned to
    const rembergUser = await firstValueFrom(
      this.store.select(GlobalSelectors.selectCurrentRembergUser),
    );
    if (ownGroupsOnly && rembergUser?.userGroupIds) {
      filterStrings.push(`${params.tableName}._id IN ('${rembergUser.userGroupIds.join("', '")}')`);
    }

    return this.getInstances(
      undefined,
      undefined,
      ColumnNamesEnum.NAME,
      SQLSortDirection.ASC,
      filterStrings.join(' AND '),
    );
  }
}
