import { Injectable } from '@angular/core';
import { BaseAskAiOverlayState, BaseAskAiOverlayStore } from './base-ask-ai-overlay.store';
import { PrefilledOverlayFeaturesEnum } from './prefilled-overlay-features.enum';

@Injectable({ providedIn: 'root' })
export class RegisterAskAiStoreProviderService {
  public readonly registeredStoreProviders: Record<
    PrefilledOverlayFeaturesEnum,
    BaseAskAiOverlayStore<BaseAskAiOverlayState> | undefined
  > = {} as Record<
    PrefilledOverlayFeaturesEnum,
    BaseAskAiOverlayStore<BaseAskAiOverlayState> | undefined
  >;
}
