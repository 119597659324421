import { Assert, FeatureFlagEnum, TwoWayCompareStringType } from '@remberg/global/common/core';
import { FilePermissions } from '@remberg/users/common/main';

export enum FilesPermissionEnum {
  FILES_ENABLED = 'files_enabled',
  FILES_UPLOAD = 'files_upload',
  FILES_REMOVE_ALL = 'files_removeAll',
  FILES_REMOVE_OWN = 'files_removeOwn',
  FILES_RENAME_ALL = 'files_renameAll',
  FILES_RENAME_OWN = 'files_renameOwn',
  FILES_ASSIGN_ALL = 'files_assignAll',
  FILES_ASSIGN_OWN = 'files_assignOwn',
  FILES_MOVE_ALL = 'files_moveAll',
  FILES_MOVE_OWN = 'files_moveOwn',
  FILES_SET_PUBLIC = 'files_setPublic',
  FILES_SHOW_ONLY_IN_ASSET_DETAILS = 'files_showOnlyInAssetDetails',
  FILES_MANAGE_AI_KNOWLEDGE_BASE = 'files_manageAiKnowledgeBase',
}

export type AssertMatchingInterfaces = Assert<
  TwoWayCompareStringType<
    `${FeatureFlagEnum.FILES}_${keyof FilePermissions}`,
    `${FilesPermissionEnum}`
  >
>;
