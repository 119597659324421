import { createReducer, on } from '@ngrx/store';
import { mergeObjects } from '@remberg/global/common/core';
import { GlobalActions } from './global.actions';
import { GlobalState } from './global.definitions';

export const INITIAL_GLOBAL_STATE: GlobalState = {
  token: undefined,
  displayAssetStatus: false,
  isGlobalInitializationComplete: false,
  localUIState: {
    isPrimaryNavigationOpen: false,
    isWorkOrderSidebarOpen: true,
    isMaintenancePlanSidebarOpen: true,
    isServiceCaseSidebarOpen: true,
  },
  loadingIds: {},
  isFullscreen: false,
};

export const globalReducer = createReducer<GlobalState>(
  INITIAL_GLOBAL_STATE,

  on(
    GlobalActions.setUserRole,
    (state, { userRole }): GlobalState => ({ ...state, userRole }) satisfies GlobalState,
  ),

  on(
    GlobalActions.breakpointsUpdated,
    (state, { breakpoints }): GlobalState => ({ ...state, breakpoints }) satisfies GlobalState,
  ),

  on(
    GlobalActions.deviceTypeInitialized,
    (state, { deviceType }): GlobalState => ({ ...state, deviceType }) satisfies GlobalState,
  ),

  on(
    GlobalActions.versionInfoInitialized,
    (state, { versionInfo }): GlobalState => ({ ...state, versionInfo }) satisfies GlobalState,
  ),

  on(GlobalActions.updateUserSettings, (state, action): GlobalState => {
    const rembergUser = state.rembergUser;
    return {
      ...state,
      rembergUser: rembergUser
        ? {
            ...rembergUser,
            settings: mergeObjects(rembergUser.settings, action.settings),
          }
        : undefined,
    } satisfies GlobalState;
  }),

  on(GlobalActions.updateUserSettingsError, (state, action): GlobalState => {
    const rembergUser = state.rembergUser;

    return {
      ...state,
      rembergUser: rembergUser
        ? {
            ...rembergUser,
            settings: {
              ...rembergUser.settings,
              ...action.oldSettings,
            },
          }
        : undefined,
    } satisfies GlobalState;
  }),

  on(GlobalActions.resetUIUserSettings, (state, action): GlobalState => {
    const rembergUser = state.rembergUser;

    return {
      ...state,
      rembergUser: rembergUser
        ? {
            ...rembergUser,
            settings: {
              doNotAskAgain: Object.keys(rembergUser.settings.doNotAskAgain ?? {}).reduce(
                (previous, key) => ({ ...previous, [key]: false }),
                {},
              ),
              choices: Object.keys(rembergUser.settings.choices ?? {}).reduce(
                (previous, key) => ({ ...previous, [key]: false }),
                {},
              ),
            },
          }
        : undefined,
    } satisfies GlobalState;
  }),

  on(
    GlobalActions.setSyncState,
    (state, { syncState }): GlobalState => ({ ...state, syncState }) satisfies GlobalState,
  ),

  on(
    GlobalActions.tenantUpdated,
    (state, { tenant }): GlobalState => ({ ...state, tenant }) satisfies GlobalState,
  ),

  on(
    GlobalActions.organizationUpdated,
    (state, { organization }): GlobalState => ({ ...state, organization }) satisfies GlobalState,
  ),

  on(
    GlobalActions.clearSyncState,
    (state): GlobalState => ({ ...state, syncState: undefined }) satisfies GlobalState,
  ),

  on(
    GlobalActions.setConnectionStatus,
    (state, { connectionStatus }): GlobalState =>
      ({ ...state, connectionStatus }) satisfies GlobalState,
  ),

  on(
    GlobalActions.setFilesDownloadUrl,
    (state, action): GlobalState =>
      ({ ...state, filesDownloadUrl: action.url }) satisfies GlobalState,
  ),

  on(
    GlobalActions.applicationUpdateAvailable,
    (state): GlobalState =>
      ({ ...state, isApplicationUpdateAvailable: true }) satisfies GlobalState,
  ),

  on(
    GlobalActions.permissionsUpdateAvailable,
    (state, { updatedUserRole }): GlobalState =>
      ({ ...state, updatedUserRole }) satisfies GlobalState,
  ),

  on(
    GlobalActions.tenantUpdateAvailable,
    (state, { updatedTenant }): GlobalState => ({ ...state, updatedTenant }) satisfies GlobalState,
  ),

  on(
    GlobalActions.applicationAndStorageUpdateInitiated,
    (state): GlobalState =>
      ({ ...state, isApplicationAndStorageUpdateInProgress: true }) satisfies GlobalState,
  ),

  on(
    GlobalActions.ionicAppVersionChecked,
    (state, { belowRequiredVersion }): GlobalState =>
      ({
        ...state,
        isIonicAppBelowMinimumRequiredVersion: belowRequiredVersion,
      }) satisfies GlobalState,
  ),

  on(
    GlobalActions.intercomWebInitialized,
    (state): GlobalState => ({ ...state, isIntercomWebInitialized: true }) satisfies GlobalState,
  ),
  on(
    GlobalActions.intercomMobileInitialized,
    (state): GlobalState => ({ ...state, isIntercomMobileInitialized: true }) satisfies GlobalState,
  ),
  on(
    GlobalActions.setIsIonicAppBelowMinimumRequiredVersion,
    (state, { isIonicAppBelowMinimumRequiredVersion }): GlobalState =>
      ({ ...state, isIonicAppBelowMinimumRequiredVersion }) satisfies GlobalState,
  ),
  on(
    GlobalActions.logoutConfirmedInvalidToken,
    GlobalActions.logoutConfirmedInterface,
    (state): GlobalState => ({ ...state, hasUserChosenToLogout: true }) satisfies GlobalState,
  ),
  on(
    GlobalActions.applicationDomainInitialized,
    (state, { applicationDomain }): GlobalState =>
      ({ ...state, applicationDomain }) satisfies GlobalState,
  ),
  on(
    GlobalActions.globalInitializationComplete,
    (state): GlobalState =>
      ({ ...state, isGlobalInitializationComplete: true }) satisfies GlobalState,
  ),
  on(
    GlobalActions.startRegisterSessionInfoLogin,
    GlobalActions.startRegisterSessionInfoAppInitialization,
    (state, { token }): GlobalState =>
      ({ ...state, token, hasUserChosenToLogout: false }) satisfies GlobalState,
  ),
  on(
    GlobalActions.globalInitializationError,
    GlobalActions.registerSessionInfoError,
    (state, { error }): GlobalState => ({ ...state, error }) satisfies GlobalState,
  ),
  on(
    GlobalActions.completeRegisterSessionInfo,
    (state, { contact, rembergUser, tenant, organization, userRole }): GlobalState =>
      ({ ...state, contact, rembergUser, tenant, organization, userRole }) satisfies GlobalState,
  ),
  on(
    GlobalActions.setTenantPublic,
    (state, { tenantPublic }): GlobalState => ({ ...state, tenantPublic }) satisfies GlobalState,
  ),
  on(
    GlobalActions.breadcrumbsUpdated,
    (state, { breadcrumbs }): GlobalState => ({ ...state, breadcrumbs }) satisfies GlobalState,
  ),
  on(
    GlobalActions.startInterfaceInitialization,
    (state): GlobalState =>
      ({
        ...state,
        localUIState: {
          ...state.localUIState,
          isPrimaryNavigationOpen: state.breakpoints?.isXSmallOrSmallView
            ? false
            : !!state.localUIState.isPrimaryNavigationOpen,
        },
      }) satisfies GlobalState,
  ),
  on(
    GlobalActions.sideNavClosed,
    (state): GlobalState =>
      ({
        ...state,
        localUIState: { ...state.localUIState, isPrimaryNavigationOpen: false },
      }) satisfies GlobalState,
  ),
  on(
    GlobalActions.sideNavToggled,
    (state): GlobalState =>
      ({
        ...state,
        localUIState: {
          ...state.localUIState,
          isPrimaryNavigationOpen: !state.localUIState.isPrimaryNavigationOpen,
        },
      }) satisfies GlobalState,
  ),
  on(
    GlobalActions.localUIStatePropertyUpdated,
    (state, action): GlobalState =>
      ({
        ...state,
        localUIState: {
          ...state.localUIState,
          ...action.uiState,
        },
      }) satisfies GlobalState,
  ),
  on(
    GlobalActions.tokenRefreshed,
    (state, { token }): GlobalState => ({ ...state, token }) satisfies GlobalState,
  ),
  on(
    GlobalActions.setRembergUser,
    (state, { rembergUser }): GlobalState => ({ ...state, rembergUser }) satisfies GlobalState,
  ),
  on(GlobalActions.twoFactorAuthenticationUpdated, (state, action): GlobalState => {
    const rembergUser = state.rembergUser;
    if (!rembergUser) {
      return state;
    }
    return {
      ...state,
      rembergUser: {
        ...rembergUser,
        twoFactorInfo: { ...rembergUser.twoFactorInfo, ...action.twoFactorInfo },
      },
    } satisfies GlobalState;
  }),
  on(GlobalActions.languageUpdated, (state, { lang }): GlobalState => {
    const rembergUser = state.rembergUser;
    if (!rembergUser) {
      return state;
    }
    return {
      ...state,
      rembergUser: { ...rembergUser, lang },
    } satisfies GlobalState;
  }),
  on(GlobalActions.timeZoneUpdated, (state, { tz }): GlobalState => {
    const rembergUser = state.rembergUser;
    if (!rembergUser) {
      return state;
    }
    return {
      ...state,
      rembergUser: { ...rembergUser, tz },
    } satisfies GlobalState;
  }),
  on(GlobalActions.contactCrmDataUpdated, (state, { crmData }): GlobalState => {
    const rembergUser = state.rembergUser;
    const contact = state.contact;
    if (!rembergUser || !contact) {
      return state satisfies GlobalState;
    }

    const primaryEmail = crmData.emails?.[0]?.email;

    return {
      ...state,
      contact: {
        ...contact,
        crmData: {
          firstName: crmData.firstName ?? contact.crmData.firstName,
          lastName: crmData.lastName ?? contact.crmData.lastName,
          emails: crmData.emails ?? contact.crmData.emails,
          phoneNumbers: crmData.phoneNumbers ?? contact.crmData.phoneNumbers,
          profilePictureId: crmData.profilePictureId ?? contact.crmData.profilePictureId,
          position: crmData.position ?? contact.crmData.position,
        },
      },
      rembergUser: {
        ...rembergUser,
        email: primaryEmail ?? rembergUser.email,
        activationInfo: {
          ...rembergUser.activationInfo,
          ...(primaryEmail !== rembergUser.email && { emailVerifiedAt: undefined }),
        },
      },
    } satisfies GlobalState;
  }),

  on(
    GlobalActions.startGlobalLoadingId,
    (state, action): GlobalState =>
      ({
        ...state,
        loadingIds: {
          ...state.loadingIds,
          [action.id]: true,
        },
      }) satisfies GlobalState,
  ),
  on(GlobalActions.stopGlobalLoadingId, (state, action): GlobalState => {
    const newLoadingIds = { ...state.loadingIds };
    delete newLoadingIds[action.id];
    return {
      ...state,
      loadingIds: { ...newLoadingIds },
    } satisfies GlobalState;
  }),

  on(
    GlobalActions.setIsFullscreen,
    (state, { isFullscreen }): GlobalState =>
      ({
        ...state,
        isFullscreen,
      }) satisfies GlobalState,
  ),

  on(
    GlobalActions.setServerName,
    (state, { serverName }): GlobalState => ({
      ...state,
      serverName,
    }),
  ),

  on(
    GlobalActions.setServerUrl,
    (state, { serverUrl }): GlobalState => ({
      ...state,
      serverUrl,
    }),
  ),
);
