import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MdePopoverModule } from '@niewicz/mde-popover';
import {
  MaterialIconsPipe,
  MaterialModule,
  SecureImagePipe,
  TransformToBase64Pipe,
  WithCacheBreakerPipe,
} from '@remberg/global/ui';
import {
  CssColorConverterPipe,
  DialogWrapperComponent,
  DynamicPopUpComponent,
  FileUrlPipe,
  ModalWrapperComponent,
  TokenRefreshPopupComponent,
} from '@remberg/ui-core/core';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AccessPopupComponent } from './dialogs/access-popup/access-popup.component';
import { DebounceDirective } from './directives/debounce.directive';
import { StrictCellDefDirective } from './directives/mat-strict-cell-def.directive';
import { StrictRowDefDirective } from './directives/mat-strict-row-def.directive';
import { MyRouterLinkDirective } from './directives/new-tab.directive';
import { ColorOptionPipe } from './pipes/color-option.pipe';
import { ContactFullNamePipe } from './pipes/contact-full-name.pipe';
import { ContainsPipe } from './pipes/contains.pipe';
import { DateFormatWithoutYearPipe } from './pipes/date-format-without-year.pipe';
import { DurationInSecondsToDisplayStringPipe } from './pipes/duration-in-seconds-to-display-string.pipe';
import { GetFormErrorMessagePipe } from './pipes/form-error-message.pipe';
import { GetIDStringPipe } from './pipes/getIDString.pipe';
import { ImageSrcUrlsPipe } from './pipes/image-src-urls.pipe';
import { IsTableEmptyPipe } from './pipes/is-table-empty.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { RembergDateDisplayPipe } from './pipes/remberg-date-display.pipe';
import { RembergDateTimeDisplayPipe } from './pipes/remberg-date-time-display.pipe';
import { RembergNumberPipe } from './pipes/remberg-number.pipe';
import { RembergTimeDisplayPipe } from './pipes/remberg-time-display.pipe';
import { RembergTimestampDisplayPipe } from './pipes/remberg-timestamp-display.pipe';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { TimezoneDiffersPipe } from './pipes/timezone-differs.pipe';
import { ToRembergDatePipe } from './pipes/to-remberg-date.pipe';
import { ToRembergTimePipe } from './pipes/to-remberg-time.pipe';
import { ToRembergTimestampPipe } from './pipes/to-remberg-timestamp.pipe';
import { UnknownImageUrlsPipe } from './pipes/unknown-image-urls.pipe';
import { PrivacyPolicyTextComponent } from './privacy-policy-text/privacy-policy-text.component';
import { AiLoaderComponent } from './ui/ai-loader/ai-loader.component';
import { ButtonDropdownComponent } from './ui/button-dropdown/button-dropdown.component';
import { ButtonListAddEntryComponent } from './ui/button-list-add-entry/button-list-add-entry.component';
import { ButtonLoaderComponent } from './ui/button-loader/button-loader.component';
import { CheckboxComponent } from './ui/checkbox/checkbox.component';
import { ColorSwatchPickerComponent } from './ui/color-swatch-picker/color-swatch-picker.component';
import { DateDisplayComponent } from './ui/date-display/date-display.component';
import { DateTimeDisplayComponent } from './ui/date-time-display/date-time-display.component';
import { EmptyListComponent } from './ui/empty-list/empty-list.component';
import { FeatureDisabledComponent } from './ui/feature-disabled/feature-disabled.component';
import { FeatureOfflineUnavailableComponent } from './ui/feature-offline-unavailable/feature-offline-unavailable.component';
import { FeatureRestrictedComponent } from './ui/feature-restricted/feature-restricted.component';
import { LanguagePickerComponent } from './ui/language-picker/language-picker.component';
import { LoaderComponent } from './ui/loader/loader.component';
import { PhoneNumberDisplayComponent } from './ui/phone-number-display/phone-number-display.component';
import { PhoneNumberInputDeprecatedComponent } from './ui/phone-number-input-deprecated/phone-number-input-deprecated.component';
import { PhoneNumberInputComponent } from './ui/phone-number-input/phone-number-input.component';
import { PreviewCardComponent } from './ui/preview-card/preview-card.component';
import { PreviewIndicatorComponent } from './ui/preview-indicator/preview-indicator.component';
import { PreviewServerInputDialogComponent } from './ui/preview-server-input-dialog/preview-server-input-dialog.component';
import { PriorityIndicatorComponent } from './ui/priority-indicator/priority-indicator.component';
import { RembergCardHoverComponent } from './ui/remberg-card-hover/remberg-card-hover.component';
import { RembergCardMapComponent } from './ui/remberg-card-map/remberg-card-map.component';
import { RembergCardComponent } from './ui/remberg-card/remberg-card.component';
import { ServerPickerComponent } from './ui/server-picker/server-picker.component';
import { SimulationPickerComponent } from './ui/simulation-picker/simulation-picker.component';
import { StatusIndicatorComponent } from './ui/status-indicator/status-indicator.component';
import { TimeDisplayComponent } from './ui/time-display/time-display.component';
import { ToastrComponent } from './ui/toastr/toastr.component';
import { ToggleWithTextsComponent } from './ui/toggle-with-texts/toggle-with-texts.component';
import { ImageLoaderComponent } from './ui/util/image-loader/image-loader.component';

const sharedMainComponents = [
  // Dialogs
  TokenRefreshPopupComponent,
  DialogWrapperComponent,
  DynamicPopUpComponent,
  ModalWrapperComponent,
  AccessPopupComponent,
  PreviewServerInputDialogComponent,
  // UI
  EmptyListComponent,
  FeatureDisabledComponent,
  FeatureRestrictedComponent,
  FeatureOfflineUnavailableComponent,
  ButtonDropdownComponent,
  ButtonListAddEntryComponent,
  ToastrComponent,
  CheckboxComponent,
  ButtonLoaderComponent,
  StatusIndicatorComponent,
  PriorityIndicatorComponent,
  LoaderComponent,
  AiLoaderComponent,
  ImageLoaderComponent,
  PreviewCardComponent,
  RembergCardComponent,
  RembergCardMapComponent,
  RembergCardHoverComponent,
  PreviewIndicatorComponent,
  DateDisplayComponent,
  TimeDisplayComponent,
  DateTimeDisplayComponent,
  PhoneNumberInputDeprecatedComponent,
  PhoneNumberInputComponent,
  PhoneNumberDisplayComponent,
  ColorSwatchPickerComponent,
  LanguagePickerComponent,
  ServerPickerComponent,
  SimulationPickerComponent,
  PrivacyPolicyTextComponent,
  ToggleWithTextsComponent,
  // Directives
  MyRouterLinkDirective,
  DebounceDirective,
  StrictCellDefDirective,
  StrictRowDefDirective,
  // Pipes
  GetIDStringPipe,
  GetFormErrorMessagePipe,
  FileUrlPipe,
  TransformToBase64Pipe,
  SecureImagePipe,
  SanitizeUrlPipe,
  MaterialIconsPipe,
  WithCacheBreakerPipe,
  CssColorConverterPipe,
  RembergDateDisplayPipe,
  RembergTimeDisplayPipe,
  RembergDateTimeDisplayPipe,
  RembergTimestampDisplayPipe,
  TimezoneDiffersPipe,
  PhoneNumberPipe,
  RembergNumberPipe,
  ContactFullNamePipe,
  ColorOptionPipe,
  IsTableEmptyPipe,
  ImageSrcUrlsPipe,
  UnknownImageUrlsPipe,
  ContainsPipe,
  DateFormatWithoutYearPipe,
  ToRembergTimestampPipe,
  ToRembergTimePipe,
  ToRembergDatePipe,
  DurationInSecondsToDisplayStringPipe,
];

@NgModule({
  imports: [
    // Angular
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    // Third-Party
    ImgFallbackModule,
    FlexLayoutModule,
    NgxUiLoaderModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MdePopoverModule,
  ],
  declarations: sharedMainComponents,
  exports: sharedMainComponents,
  providers: [RembergDateDisplayPipe, RembergTimeDisplayPipe],
})
export class SharedMainModule {}
